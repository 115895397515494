import {combineReducers} from "redux";
import balanceReducer, {selectIsSolved as balanceSolved} from "./balance/feature"
import coffreReducer, {selectIsSolved as coffreSolved} from "./coffre/feature"
import carnetReducer, {selectIsSolved as carnetSolved} from "./carnet/feature"
import epeeReducer, {selectIsSolved as epeeSolved} from "./epee/feature"
import {createSelector} from "@reduxjs/toolkit";

export default combineReducers({
    balance: balanceReducer,
    coffre: coffreReducer,
    carnet: carnetReducer,
    epee: epeeReducer,
})


export const selectIsSolved = createSelector(
    balanceSolved,
    epeeSolved,
    coffreSolved,
    carnetSolved,
    (balance, epee, coffre, carnet) => {
        console.log(">> ", {balance,epee, coffre, carnet})
        return epee && balance && carnet && coffre
    })