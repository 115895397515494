import {createSelector, createSlice} from '@reduxjs/toolkit'

export const progressSlice = createSlice({
  name: 'progress',
  initialState: {
    inventory: {},
  },
  reducers: {
    loadPage: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state[action.payload] = true
    },
    addToInventory: (state, action) => {
      state.inventory[action.payload] = true
    }
    // decrement: (state) => {
    //   state.value -= 1
    // },
    // incrementByAmount: (state, action) => {
    //   state.value += action.payload
    // },
  },
})

// Action creators are generated for each case reducer function
export const { loadPage, addToInventory } = progressSlice.actions

const selectSelf = state => state.progress
export const selectHasNavigation = createSelector(selectSelf, state => state['/chap1/bagagespackstart'])
export const selectHasAccess = (link) => createSelector(selectSelf, state => !!state[link])
export const selectHasInventory = createSelector(selectSelf, state => Object.keys(state.inventory).length > 0)
export const selectHasInInventory = (item) => createSelector(selectSelf, state => !!state.inventory[item])

export default progressSlice.reducer