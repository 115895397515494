import { configureStore } from '@reduxjs/toolkit'
import progressReducer from './features/progress'
import { offline } from '@redux-offline/redux-offline'
import offlineConfig from '@redux-offline/redux-offline/lib/defaults';
import navigationMenuReducer from "./features/navigation-menu";
import chap1Reducer from "./pages/chap1/features";
import chap2Reducer from "./pages/chap2/features";
import chap3Reducer from "./pages/chap3/features";
import chap4Reducer from "./pages/chap4/features";

/*
let preloadedState = null
if(window.localStorage){

}
*/

const store = configureStore({
  reducer: {
    progress: progressReducer,
    navmenu: navigationMenuReducer,
    chap1: chap1Reducer,
    chap2: chap2Reducer,
    chap3: chap3Reducer,
    chap4: chap4Reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [offline(offlineConfig)]
  // preloadedState
})
/*
store.subscribe(()=>{
  window.localStorage && window.localStorage.setItem('reduxState', JSON.stringify(store.getState()))
})
*/

export default store