import {Link} from "react-router-dom";
import './main-nav.css'
import React from "react";
import {useSelector} from "react-redux";
import {selectHasAccess, selectHasInInventory, selectHasInventory} from "../../features/progress";

const MainNavProtectedItem = ({link, children}) => {
    const access = useSelector(selectHasAccess(link))
    if (access) {
        return (<li><Link to={link}>{children}</Link></li>)
    } else {
        return null
    }
}

const MainNavProtectedInventoryItem = ({object, children}) => {
    const access = useSelector(selectHasInInventory(object))
    if (access) {
        return (<li>{children}</li>)
    } else {
        return null
    }
}

const CarnetNav = () => {
    return (
        <li>
            Carnet
            <ul>
                <li><Link to="/notebook/commencement">Au commencement</Link></li>
                <li><Link to="/notebook/plan_bateau">Plan du bateau</Link></li>
                <li><Link to="/notebook/crosswords">À Emporter</Link></li>
                <li><Link to="/notebook/pirahas">Pirahas</Link></li>
                <MainNavProtectedInventoryItem object="notebook.p5">
                    <Link to="/notebook/helpcryptocercles">Crypto aide</Link>
                </MainNavProtectedInventoryItem>
                <li><Link to="/notebook/cryptocercles">Crypto cercles</Link></li>
            </ul>
        </li>
    )
}

const MainNav = () => {
    const hasInventory = useSelector(selectHasInventory)
    const hasCarnet = useSelector(selectHasInInventory("carnet"))
    const hasChap2 = useSelector(selectHasAccess("/chap2/videodepart"))
    const hasChap3 = useSelector(selectHasAccess("/chap3/macapa"))
    const hasChap4 = useSelector(selectHasAccess("/chap4/pirahas"))
    return (
        <div className="Main-Nav-dropout">
            <div className="Main-Nav">
                <h2>Progression</h2>
                <h4>version 4.1</h4>
                <ul>
                    <li><Link to="/intro">Intro</Link></li>
                    <li><Link to="/chap1/bagagespackstart">Chapitre I</Link></li>
                    <ul>
                        <MainNavProtectedItem link="/chap1/bagagespackstart">Prête?</MainNavProtectedItem>
                        <MainNavProtectedItem link="/chap1/piece">
                            Faire ses bagages
                        </MainNavProtectedItem>
                        <ul>
                            <MainNavProtectedItem link="/chap1/balance">4 poids, 40 mesures</MainNavProtectedItem>
                            {hasCarnet ? null : <MainNavProtectedItem link="/chap1/armoire">Armoire</MainNavProtectedItem>}
                            <MainNavProtectedItem link="/chap1/carnet">Conseil du Carnet</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap1/coffre">Coffre</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap1/epee">Le jeu des couleurs</MainNavProtectedItem>
                        </ul>
                    </ul>
                    {hasChap2 ? <React.Fragment>
                        <MainNavProtectedItem link="/chap2/videodepart">Chapitre II</MainNavProtectedItem>
                        <ul>
                            <MainNavProtectedItem link="/chap2/videodepart">On prend le large</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap2/meurtre">Meurtre en haute mer</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap2/interrogatoire">Interrogatoire</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap2/requins">Aux requins</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap2/arrivee">Sur les côtes</MainNavProtectedItem>
                        </ul>
                    </React.Fragment>: null}
                    {hasChap3 ? <React.Fragment>
                        <MainNavProtectedItem link="/chap3/macapa">Chapitre III</MainNavProtectedItem>
                        <ul>
                            <MainNavProtectedItem link="/chap3/macapa">Macapa</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap3/mapuce">Mapuce</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap3/malaury">Malaury</MainNavProtectedItem>
                            <MainNavProtectedItem link="/chap3/marocanar">Marocanar</MainNavProtectedItem>
                        </ul>
                    </React.Fragment>: null}
                    {hasChap4 ? <React.Fragment>
                        <MainNavProtectedItem link="/chap4/pirahas">Chapitre IV</MainNavProtectedItem>
                        <ul>
                            <MainNavProtectedItem link="/chap4/pirahas">Pirahas</MainNavProtectedItem>
                        </ul>
                    </React.Fragment>: null}
                </ul>
                {hasInventory ?
                    <React.Fragment>
                        <h2>Inventaire</h2>
                        <ul>
                            <MainNavProtectedInventoryItem object="piece_tresor">Pièce du
                                trésor</MainNavProtectedInventoryItem>
                            {hasCarnet ? <CarnetNav/> : null}
                            <MainNavProtectedInventoryItem object="balance">Balance</MainNavProtectedInventoryItem>
                            <MainNavProtectedInventoryItem object="money">Finances pour le voyage</MainNavProtectedInventoryItem>
                            <MainNavProtectedInventoryItem object="epee">Armes pour le voyage</MainNavProtectedInventoryItem>
                            <MainNavProtectedInventoryItem object="listeequipage">
                                <Link to="/chap2/listeequipage">Liste de l'équipage</Link>
                            </MainNavProtectedInventoryItem>
                        </ul>
                    </React.Fragment> : null}
            </div>
        </div>
    );
}

export default MainNav;