import logo from "./logo.svg";
import React from 'react';
import {Link} from "react-router-dom";
import './home.css'

function Home() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
            <p>
                Ahoy Isaline !
            </p>
            <Link
                className="App-link"
                to="/intro"
            >
                Débuter &#8250;
            </Link>
        </header>
    );
}

export default Home;