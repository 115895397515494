import React from 'react';
import './interrogatoire.css'
import {Link} from "react-router-dom";

function Interrogatoire() {
    return (
        <div className="written-page Page-Interro">
            <h2>
                Chap. II : Interrogatoire
            </h2>
            <p>
                Tu commences par descendre aux Cabines. Ici tu rencontres Molas et Estrella qui s'affairent autour d'un
                tonneau.<br/>
                Commençons par leur demander tout ce qu'ils savent...
            </p>
            <h4>Sebastien Molas & Paulo Estrella</h4>
            <blockquote>
                On s'affaire depuis 2 heures à ranger ces tonneaux avec l'un des Nolsen. <br/>
                On en a entendu un se fracasser tout à l'heure. On a
                accouru et essaie de remettre de l'ordre.<br/>
                Voyez ce tonneau de rhum s'est renversé. Tout ce précieux liquide perdu...<br/>
                Le frère Nolsen qui était avec nous est descendu il y a 5 minutes pour voir ce qui se passait en bas.
            </blockquote>
            <p>Tu vois effectivement le reste d'une flaque bien nettoyée de rhum au sol et le tonneau est entièrement
                vide</p>
            <p>Tu poursuis ta descente vers la Cahute. C'est là que l'équipage prend son repas. Tu y croises d'abord
                Bryan Kitchen</p>
            <h4>Bryan Kitchen</h4>
            <blockquote>
                C'est terrible ! J'ai découvert le corps de Fallicido. Quelle vision d'horreur! Je venais juste de
                quitter cette pièce en ayant fini de manger.<br/>
                J'ai croisé Cheek qui sortait tout juste du Pont et nous avions décidé d'aller faire un tour au bout du
                Beaupré pour prendre un peu l'air.<br/>
                Malheureusement, sur le chemin, sur le pont principal, nous avons fait la découverte macabre dont le
                second vous a informé.
            </blockquote>
            <p>Dans la même salle, il y a le cuisinier Rilsen qui s'affaire à ranger des plats</p>
            <h4>Rackam Rilsen</h4>
            <blockquote>
                Quelle affaire ! On était en train de finir le grand repas de rattrapage pour tous ceux qui n'avaient
                pas
                mangé hier. Ils avaient tous très faim, moi compris, et on a bien profité du repas servi
                exceptionnellement depuis 5h30.<br/>
                Raymond et moi étions en train de tout ranger quand on a entendu
                Bryan et Cheek crier d'effroi.
            </blockquote>
            <p>Tu continues vers l'arrière de la cabine et tu vois Juan Vargas en train de manipuler des poissons</p>
            <h4>Juan Vargas</h4>
            <blockquote>
                Quelle remue ménage ce matin. J'étais en train de mettre dans les provisions ma pêche de ce matin.<br/>
                Je passe toujours 3 heures à pêcher avant de me mettre au boulot. Ça me detends de ce boulot de
                charpentier éreintant.<br/>
                Ce matin la pêche a été bonne j'ai attrapé 2 merlans, 4 brochets, 6 truites. Regardez-les : ils en sont
                pas beaux ?<br/>
                Quoiqu'il en soit je pêchais depuis le gaillard arrière. J'ai entendu un peu de tumulte vers 7h puis
                calme plat.<br/>
                Il semble que c'était quelques paroles échangées sèchement entre deux personnes dans ma langue natale,
                mais je n'ai pas pu saisir ce qui a été dit. Et ça s'est vite arrêté.<br/>
                Je ne me suis pas plus inquiété que ça, probablement deux ivrognes de matelots.
            </blockquote>
            <p>Après avoir fait plaisir à Juan en admirant ses poissons, tu retournes vers le Pont. Tu manques de tomber
                à cause d'Erik Roostendal étalé de tout son long tout
                près de l'accès menant au pont. <br/>
                Il a l'air d'émerger doucement d'un lendemain de cuite...</p>
            <h4>Erik Roostendal</h4>
            <blockquote>
                Blurp ! Maiiiiis, hey, ne parlez pas trop fort. C'est quoi tout ce ramdam ! Depuis 10 minutes toute le
                monde va & vient
                dans tous les sens... Laissez-moi dormir encore un peu.<br/>
                Il n'y a pas de navire à couler par le fond que je saches ?! Alors laissez-moi profiter encore un peu de
                la matinée.<br/>
                Prévenez-moi si je dois faire chauffer mes canons... Blurp.
            </blockquote>
            <p>Il faut enjamber Erik mais tu parviens sur le Pont où l'équipage a commencé à se réunir en partie.<br/>
                Fouchet vient vers toi et il semble avoir quelque chose à te communiquer.</p>
            <h4>Raymond Fouchet</h4>
            <blockquote>
                Je vous garantis que ce n'est pas moi Capitaine. Oui, c'est bien mon couteau planté là-bas, sur le pont
                principal, dans le corps de ce malheureux.<br/>
                Mais je vous assure que ce n'est pas moi. Croyez-moi. J'avais le couteau avec moi ce matin quand j'ai
                préparé le repas pour tous ces affamés. <br/>
                Quelqu'un a dû le voler après.<br/>C'est un complot, on veut me faire porter le chapeau !
            </blockquote>
            <p>Perplexe, tu laisses Fouchet ainsi que les autres membres d'équipage réunis ici. <br/> Ton second leur
                demande de ne pas quitter le bateau jusqu'à ce que l'affaire soit résolue... Est-ce de l'humour ou le
                choc du meurtre lui a fait oublier qu'on est en plein milieu de l'océan...</p>
            <p>Tu descends d'un étage, ton vieil ami Pierre Jacquet est en train de remonter depuis la cale</p>
            <h4>Pierre Jacquet</h4>
            <blockquote>
                Et bien ! C'était plutôt dur de dormir ce matin. Depuis 2 heures il y en a 2 qui ont décidé de rentrer
                dans une joute verbale infinie. <br/>
                En plus on n'en comprend rien, ça parlait en portugais... Le peu de mots que j'ai compris c'était une
                histoire de famille qu'ils essayaient de régler.<br/>
                Je te garantis que je vais les retrouver et qu'il vont entendre parler de moi ces deux-là.
            </blockquote>
            <p>Apparemment il n'était pas encore au courant du meurtre. Tu l'en informe et il est horrifié.</p>
            <p>Ferndinand Riviera et Laars Nolsen t'ont suivi depuis le Pont. Ils attendent que Jacquet monte et te
                tapent sur l'épaule et te parlent à voix basse</p>
            <h4>Ferndinand Riviera & Laars Nolsen</h4>
            <blockquote>
                Capitaine, nous avons des informations importantes pour vous ! On ne sait pas si c'est lié au meurtre,
                mais on observe depuis notre départ de Naussau ces bachi-bouzouk d'Anglais.<br/>
                Depuis 5h45 ce matin on les a observé jouer de l'argent. Ils se réunissent sur la Poulaine et ils misent
                gros.<br/>
                C'est totalement interdit par les règles de vie à bord.<br/>Peut être que Fallecido les auras démasqués
                et qu'il l'ont tué dans la nuit puis qu'ils sont allé joué comme si de rien n'était ! <br/>
                Ce qui est sûr c'est qu'il étaient sept ! Et on a reconnu le maître d'équipage Reinards dans le lot.
            </blockquote>
            <p>Le mystère s'epaissi... Tu continues ta route sur ton bateau en évitant le pont principal. L'idée de voir
                du sang de bon matin te dégoute un peu.</p>
            <p>Tu croises alors Octavio qui semble descendre vers la cale. Tu lui apprends ce qui se passe sur le
                bateau.</p>
            <h4>Octavio Hemingway</h4>
            <blockquote>
                Oh mon Dieu ! Encore un meurtre, mais ça ne s'arrêtera donc jamais ! Je m'étais engagé à 16 ans, plein
                d'espoir, pour
                voguer sur les mers, je ne m'attendais pas à ce que mes deux premières experiences soient sur des bateaux
                maudits !
            </blockquote>
            <p>
                Tu laisses Octavio tremblant à l'annonce de la nouvelle que tu viens de lui faire. Ça semble lui rappeler de mauvais souvenirs.
                Tu atteins le Gaillard d'avant. Jose Vega était de surveillance ce matin à ce poste.</p>
            <h4>Jose Vega</h4>
            <blockquote>
                Au rapport ! Mon tour de garde s'est passé sans encombre jusqu'à il y a une heure. Je suis resté éveillé
                toute la nuit.<br/>
                Mais il y a une heure, alors que je passais ici sur le gaillard avant, quelqu'un m'a sauté dessus en
                glissant depuis le mât et m'a assommé.<br/>
                La seule chose que j'ai pu voir c'est qu'il avait un pantalon tel que les porte les Gabiers et les
                Matelots.
            </blockquote>
            <p>Très bien, tu laisses Jose Vega qui semble encore un peu émerger. </p>
            <p>Tu en as assez entendu. Il est temps de résoudre cette affaire...</p>

            <Link
                className="App-link"
                to="/chap2/requins"
            >
                AUX REQUINS &#8250;
            </Link>
        </div>
    );
}

export default Interrogatoire