import {createSelector, createSlice} from '@reduxjs/toolkit'

export const requinsSlice = createSlice({
    name: 'requins',
    initialState: {
        solved: false,
        message: "",
        killed: []
    },
    reducers: {
        proposeSolution: (state, action) => {
            let name = action.payload
            state.killed.push(name)
            let ok = name.toLowerCase().includes("vargas")
            state.message = ""
            if(ok){
                state.solved = true
            }else{
                state.message = "Un innocent à la mer... en tout cas innoncent du meurtre... Mais tu avais probablement une bonne raison Capitaine"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution} = requinsSlice.actions

const selectSelf = (state) => state.chap2.requins
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectKilled = createSelector(selectSelf, state => state.killed)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default requinsSlice.reducer