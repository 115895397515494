import React, {useCallback, useEffect} from 'react';
import "./coffre.css"
import {useDispatch, useSelector} from "react-redux";
import {proposeSolution, proposeSolution2, selectIsSolved, selectIsSolved2, selectMessage} from "./feature";
import cadenas from "./cadenas.png"
import img3321 from "./3321.png"
import img4321 from "./4321.png"
import img5482 from "./5482.png"
import {addToInventory} from "../../../features/progress";
import {Link} from "react-router-dom";
import * as PropTypes from "prop-types";

function MainForm({onSubmit}) {
    return <form onSubmit={onSubmit} method="GET" id="coffre-form" className="coffre-form">
        <span>Combinaison :&#160;</span>
        <input type="number" name="combinaison"/>
        <button type="submit">Valider</button>
    </form>;
}

function SecondaryForm({onSubmit}) {
    return <form onSubmit={onSubmit} method="GET" id="coffre-form2" className="coffre-form secondary-form">
        <span>Pour gagner encore plus d'argent, Daniel avait d'autres coffres avec des cadenas similaires, mais seuls les symboles suivant étaient présents</span>
        <label><img src={img3321} alt="devine moi"/><input type="number" name="c1"/></label>
        <label><img src={img4321} alt="devine moi"/><input type="number" name="c2"/></label>
        <label><img src={img5482} alt="devine moi"/><input type="number" name="c3"/></label>
        <button type="submit">Valider</button>
    </form>;
}

MainForm.propTypes = {onSubmit: PropTypes.func};

function Coffre() {
    const dispatch = useDispatch()
    const onForm1Submit = useCallback((event) => {
        event.preventDefault()
        dispatch(proposeSolution(event.target.combinaison.value))
    }, [dispatch])
    const onForm2Submit = useCallback((event) => {
        event.preventDefault()
        const f = event.target
        dispatch(proposeSolution2({
            n1: f.c1.value,
            n2: f.c2.value,
            n3: f.c3.value,
        }))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)
    const solved2 = useSelector(selectIsSolved2)

    useEffect(() => {
        if (solved) {
            dispatch(addToInventory("money"))
        }
    }, [dispatch, solved])
    return (
        <div className="written-page Coffre-Page">
            <h2>
                Chap. I : Coffre
            </h2>
            <p>
                Tu auras besoin d'argent dans ton voyage. <br/>
                Le contenu de ce coffre est à toi si tu arrives à déchiffrer la combinaison de Daniel.
            </p>
            <img src={cadenas} alt="Cadenas" width="100%"/>

            {message ? <p className="error">{message}</p> : null}
            {solved ?
                <React.Fragment>
                    <div style={{fontSize: "21px", marginTop: "15px"}}>
                        Bravo tu as récupéré l'argent du coffre ! <br/>
                        La combinaison était <b>1124</b><br/>
                        <Link to="/chap1/piece">Retourner à la piece des bagages</Link>
                    </div>
                    {solved2 ? <p>Bravo tu as tout trouvé !</p> : <SecondaryForm onSubmit={onForm2Submit}/>}
                </React.Fragment>
                :
                <MainForm onSubmit={onForm1Submit}/>
            }
            <br/>&#160;

        </div>
    );
}

export default Coffre