import {createSelector, createSlice} from '@reduxjs/toolkit'

export const coffreSlice = createSlice({
    name: 'coffre',
    initialState: {
        solved: false,
        solved2: false,
        message: ""
    },
    reducers: {
        proposeSolution: (state, action) => {
            let solution = parseInt(action.payload)
            state.message = ""
            state.solved = false
            const ok = (solution === 1124)
            if(ok){
                state.solved = true
            }else{
                state.message = "Cette combinaison ne fonctionne pas"
            }
        },
        proposeSolution2: (state, action) => {
            const {n1, n2, n3} = action.payload
            let s1 = parseInt(n1)
            let s2 = parseInt(n2)
            let s3 = parseInt(n3)
            state.message = ""
            state.solved2 = false
            const ok = (s1 === 3321) && (s2 === 4321) && (s3 === 5482)
            if(ok){
                state.solved2 = true
            }else{
                state.message = "Ce ne sont pas les bonnes combinaisons"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution, proposeSolution2} = coffreSlice.actions

const selectSelf = (state) => state.chap1.coffre
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectIsSolved2 = createSelector(selectSelf, state => !!state.solved2)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default coffreSlice.reducer