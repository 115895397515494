import {Route, Switch} from "react-router-dom";
import Pirahas from "./pirahas";
import CoffreTemple from "./coffretemple";

const Chap4Routes = () => (
    <Switch>
        <Route path='/chap4/pirahas' component={Pirahas}/>
        <Route path='/chap4/coffretemple' component={CoffreTemple}/>
    </Switch>
)
export default Chap4Routes