import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './marocanar.css'

import {proposeSolution, selectIsSolved, selectMessage} from "./feature";
import {Link} from "react-router-dom";

function Marocanar() {
    const dispatch = useDispatch()
    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        const form = event.target
        dispatch(proposeSolution({
            rio1: form.rio1.value,
            rio2: form.rio2.value,
            rio3: form.rio3.value,
        }))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)
    return (
        <div className="written-page Page-Marocanar">
            <h2>
                Chap. III : Marocanar
            </h2>
            <p>
                En partant de <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Macap%C3%A1,+Amap%C3%A1,+Br%C3%A9sil/@0.1019439,-51.1670718,12z/data=!3m1!4b1!4m5!3m4!1s0x8d61e5e06b4b1a37:0x56357df330df0f97!8m2!3d0.0355715!4d-51.0705364?hl=fr">
                Macapa
            </a>&#160;
                tu vas devoir guider ton équipage jusqu'au territoire des Pirahas.<br/>
                Utilise les informations que tu as sur les Pirahas ainsi que l'aide de Goglus Mapuce, pour trouver
                l'ordre des rivières à suivre pour arriver à destination.<br/>
                <b>MAIS ATTENTION</b> les informations que tu as proviennent d'une encyclopédie qui parle d'un fleuve
                Posto Novo qui n'existe pas sur les cartes de Goglus.<br/>
                Sur les cartes de Goglus ce fleuve porte un autre nom.<br/>
                Tu devras ici donner les noms des fleuves et rivières sur les cartes de Goglus Mapuce, depuis la Macapa
                jusqu'à arriver là où vivent les Pirahas.
            </p>

            {message ? <p className="error">{message}</p> : null}
            {solved ? <p>
                    Félicitation ! Tu es arrivée chez les Pirahas. <br/>
                    <br/>
                    <br/>
                    <Link
                        className="App-link"
                        to="/chap4/pirahas">Chez les pirahas
                    </Link>
                </p> :
                <form onSubmit={onFormSubmit} method="GET">
                    <label><span>Fleuve :</span>
                        <input type="text" readOnly={true} disabled={true} value="Amazone"/></label>
                    <label>
                        <span>Rio :</span>
                        <input type="text" name="rio1"/>
                    </label>
                    <label>
                        <span>Rio dos :</span>
                        <input type="text" name="rio2"/>
                    </label>
                    <label>
                        <span>Cours d'eau (et lieu de vie des Pirahas) :</span>
                        <input type="text" name="rio3"/>
                    </label>


                    <button type="submit">Valider</button>
                </form>}

        </div>
    );
}

export default Marocanar