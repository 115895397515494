import {createSelector, createSlice} from '@reduxjs/toolkit'

export const computeItemKey = (rowIdx, colIdx) => `${rowIdx}-${colIdx}`
const sortKeys = (k1, k2) => {
    let [x1, y1] = k1.split("-");
    let [x2, y2] = k2.split("-");
    [x1, x2, y1, y2] = [parseInt(x1), parseInt(x2), parseInt(y1), parseInt(y2)]
    if (x1 === x2) {
        if (y1 === y2) return 0
        else return (y1 > y2) ? 1 : -1;
    } else {
        return (x1 > x2) ? 1 : -1;
    }
}

const CONTROL = [
    "0-9",
    "1-9",
    "2-9",
    "3-6",
    "3-7",
    "3-8",
    "3-9",
    "4-6",
    "5-3",
    "5-4",
    "5-5",
    "5-6",
    "6-3",
    "7-3",
    "8-3",
    "9-3",
    "9-4",
    "9-5",
    "10-5",
    "11-5",
    "12-3",
    "12-4",
    "12-5",
    "13-3",
    "14-3",
    "14-4",
    "14-5",
    "14-6",
    "14-7",
    "14-8",
    "14-9",
    "15-9",
    "15-13",
    "15-14",
    "15-15",
    "15-16",
    "15-17",
    "16-9",
    "16-13",
    "16-17",
    "16-18",
    "16-19",
    "17-9",
    "17-10",
    "17-11",
    "17-12",
    "17-13",
    "17-19",
    "18-19",
    "19-19"
]

export const pirahasSlice = createSlice({
    name: 'pirahas',
    initialState: {
        clickedItems: {},
        message: "",
        deathCount: 0,
        success: false
    },
    reducers: {
        toggleItem: (state, action) => {
            const key = computeItemKey(action.payload.rowIdx, action.payload.colIdx)
            if (state.clickedItems[key]) {
                delete state.clickedItems[key]
            } else {
                state.clickedItems[key] = true
            }
        },
        validatePuzzle: (state, action) => {
            const selectedItems = []
            for (let k in state.clickedItems) {
                if (state.clickedItems.hasOwnProperty(k) && !!state.clickedItems[k]) {
                    selectedItems.push(k)
                }
            }
            const sortedItems = selectedItems.sort(sortKeys)
            let error = false;
            for(let i=0; i<sortedItems.length; i++){
                if(i > CONTROL.length-1){
                    error = true
                } else if(sortedItems[i] !== CONTROL[i]){
                    error = true
                }
                if(error){
                    delete state.clickedItems[sortedItems[i]]
                }

                console.log("Checking ", sortedItems[i], CONTROL[i])
            }
            let success = true
            if (!error && sortedItems.length !== CONTROL.length) {
                state.message = "Tu n'es pas arrivée jusqu'au temple mais tu es sur la bonne voie"
                success = false
            }
            if (error) {
                state.message = "Tu es tombée dans un piège ! "
                state.deathCount += 1
                success = false
            }
            state.success = success
        },
    },
})

// Action creators are generated for each case reducer function
export const {toggleItem, validatePuzzle} = pirahasSlice.actions

const selectSelf = (state) => state.chap4.pirahas
export const selectClickedItems = createSelector(selectSelf, state => state.clickedItems)
export const selectDeathCount = createSelector(selectSelf, state => state.deathCount)
export const selectMessage = createSelector(selectSelf, state => state.message)
export const selectSuccess = createSelector(selectSelf, state => state.success)

export default pirahasSlice.reducer