import React, {useCallback} from 'react';
import './piece.css'
import {ReactComponent as ReactRoom} from "./room_pure.svg"
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {addToInventory, selectHasInInventory} from "../../../features/progress";
import {selectIsSolved} from "../features";

function PieceBagages() {
    const history = useHistory();
    const dispatch = useDispatch()

    const clickOnItem = useCallback((e) => {
        const elemId = e.target.id
        if (elemId === "balance") {
            history.push("/chap1/balance")
        } else if (elemId === "carnet") {
            history.push("/chap1/armoire")
        } else if (elemId === "epee") {
            history.push("/chap1/epee")
        } else if (elemId === "coffre") {
            history.push("/chap1/coffre")
        }
    }, [history])
    const onChap2Click = useCallback((e) => {
        dispatch(addToInventory("listeequipage"))
        history.push("/chap2/videodepart")
    }, [history, dispatch])
    const hasBalance = useSelector(selectHasInInventory("balance"))
    const hasCarnet = useSelector(selectHasInInventory("carnet"))
    const hasEpee = useSelector(selectHasInInventory("epee"))
    const hasMoney = useSelector(selectHasInInventory("money"))
    const isSolved = useSelector(selectIsSolved)
    let className = "room-picture"
    hasBalance && (className += " no-balance")
    hasCarnet && (className += " no-carnet")
    hasEpee && (className += " no-epee")
    hasMoney && (className += " no-coffre")
    return (
        <div className="Page-Piece written-page">
            <h2>
                Chap. I : Faire ses bagages
            </h2>
            {isSolved ? <React.Fragment>
                <h3 className="bravo">BRAVO !!!<br/>Tu as collecté tout ce dont tu as besoin !</h3>
                <p>Je rajoute à ton inventaire la liste de ton équipage dès que tu cliqueras sur le bouton ci-dessous.</p>
                <p>Prête pour le chapitre 2?</p>
                <button onClick={onChap2Click}>LEVER L'ENCRE</button>
            </React.Fragment> : <React.Fragment>
                <p>
                    L'image ci-dessous est interactive. Clique sur les objets pour en découvrir plus.
                </p>
                <ReactRoom onClick={clickOnItem}
                           className={className}
                           height="100%"
                           preserveAspectRatio="xMinYMin slice"
                           width="100%"/>
            </React.Fragment>}
        </div>
    );
}

// Pour moi l'accouchement est avant la grossesse, l'enfance avant la naissance, l'adolescence avant l'enfant, la mort
// avant la vie. Qui suis-je?


// Je suis d'eau,
// je suis d'air,
// et je suis d'électricité.
//
// Qui suis-je ?
export default PieceBagages