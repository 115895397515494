import React from 'react';
import './videodepart.css'
import videodepart from './depart_pirate.short.fstart.mp4'
import {Link} from "react-router-dom";

function VideoDepart() {
    return (
        <div className="written-page Page-VideoDepart">
            <h2>
                Chap. II : On prend le large !
            </h2>
            <p className="video">
                <video src={videodepart} controls={true} autoPlay={true}/>
            </p>
            <p>
                C'est parti en direction des côtes du <b>Brésil</b> ! <br/>
                Tu lèves l'encre à la nuit tombé le soir de la nouvelle lune. <br/>
                Les deux premiers jours de mer sont tranquilles et le temps est clément.<br/>
                Au troisième jour, le bateau essuie une forte tempête et le mât de misaine se casse.
                Les membres d'équipages pour qui c'est la première experience en haute mer ne passe pas une très bonne
                journée, si bien qui jeuneront tous ce jour là.<br/>
            </p>

            <Link
                className="App-link"
                to="/chap2/meurtre"
            >
                PASSER LA NUIT &#8250;
            </Link>
        </div>
    );
}

export default VideoDepart