import React from 'react';
import {Link} from "react-router-dom";
import './macapa.css'
import {useSelector} from "react-redux";
import {selectIsSolved as selectMapuceSolved, selectIsSolved as selectMalaurySolved} from "../mapuce/feature";

function Macapa() {

    const didSolveMapuce = useSelector(selectMapuceSolved)
    const didSolveMalaury = useSelector(selectMalaurySolved)

    return (
        <div className="written-page Page-Macapa">
            <h2>
                Chap. III : Macapa
            </h2>
            {didSolveMapuce ? <p>
                Goglus Mapuce fait désormais parti de ton équipage, tu vas pouvoir embarquer vers les terres de Pirahas
            </p> : <React.Fragment>
                <p>
                    Il te faut absolument trouver un pilote / cartographe pour s'enfoncer dans les méandres des
                    fleuves.<br/>
                    Il y en a un très bon disponible en ville, il s'appelle Goglus Mapuce.
                    Mais il te faudra un peu de travail pour :
                </p>
                <Link
                    className="App-link"
                    to="/chap3/mapuce">Engager Mapuce
                </Link>
            </React.Fragment>}

            <hr/>
            {didSolveMalaury ? <p>
                Tu as desormais la page 5 du carnet d'Everett
            </p> : <React.Fragment>
                <p>
                    Voyant que tu arrives à Macapa sur ton navire, un marchand t'interpelle.<br/>
                </p>
                <blockquote>
                    Bonjour ! Je me présente, je suis Malaury Logulus. Je vois que vous naviguez sur le bateau de mon
                    ami Daniel Everett !<br/>
                    Passez me voir à l'occasion dans ma boutique, j'ai peut-être quelque chose qui pourrait vous
                    intéresser.
                </blockquote>
                <Link
                    className="App-link"
                    style={{"margin": "10px"}}
                    to="/chap3/malaury">Rendre de visite à Malaury
                </Link>
            </React.Fragment>}
            {didSolveMalaury && didSolveMapuce ? <React.Fragment>
                <hr/>
                <p>
                    Ton nouveau navire à fond plat pour t'enfoncer dans les rivières Brésiliennes est prêt.<br/>
                    Il s'agit du Marocanar, une embarcation petite et bruyante mais rapide aux endroits avec peu de
                    fond.
                </p>

                <Link
                    className="App-link"
                    style={{"margin": "10px"}}
                    to="/chap3/marocanar">Naviguer
                </Link>
            </React.Fragment> : null}
        </div>
    );
}

export default Macapa