import React, {useCallback, useEffect} from 'react';
import "./balance.css"
import {useDispatch, useSelector} from "react-redux";
import {proposeSolution, selectIsSolved, selectMessage} from "./feature";
import balance from "./balance.png"
import {addToInventory} from "../../../features/progress";
import {Link} from "react-router-dom";

function Balance() {
    const dispatch = useDispatch()
    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        dispatch(proposeSolution([
            parseInt(event.target.p1.value),
            parseInt(event.target.p2.value),
            parseInt(event.target.p3.value),
            parseInt(event.target.p4.value),
        ]))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)

    useEffect(() => {
        if (solved) {
            dispatch(addToInventory("balance"))
        }
    }, [dispatch, solved])
    return (
        <div className="written-page Balance-Page">
            <h2>
                Chap. I : 4 poids, 40 mesures
            </h2>
            <p>
                Lors de ton voyage, tu vas être en contact avec divers marchands et tribus. <br/>
                Afin de faire du troc et d'acheter des marchandise, il va te falloir de quoi mesurer des poids.
            </p>
            <p>
                Je te laisse amener la balance que voici.
            </p>
            <div className="text-center">
                <img src={balance} alt="Balance à poids"/>
            </div>
            <p>
                Mais, à cause de la place sur le bateau, tu ne pourras amener
                avec toi que <b>4 poids</b>
                <br/>
                Les poids à ta disposition ont tous des valeurs entières.
                <br/>
                Et avec ces 4 poids tu devras être capable de mesurer tout nombre entier de kilogramme allant de 1 à 40.
            </p>

            {message ? <p className="error">{message}</p> : null}
            {solved ?
                <div style={{fontSize: "21px", marginTop: "15px"}}>
                    Bravo tu as résolu cette énigme ! <br/>
                    Tu emporte avec toi les poids de 1, 3, 9, 27 kg<br/>
                    <Link to="/chap1/piece">Retourner à la piece des bagages</Link>
                </div>
                :
                <React.Fragment>
                    <p>
                        Quels sont les poids que tu prends ?
                    </p>
                    <form onSubmit={onFormSubmit} method="GET" id="balance-form">
                        <div className="Poids-List">
                            <div>
                                <span>Poids 1 : </span><input type="number" name="p1"/>
                            </div>
                            <div>
                                <span>Poids 2 : </span><input type="number" name="p2"/>
                            </div>
                            <div>
                                <span>Poids 3 : </span><input type="number" name="p3"/>
                            </div>
                            <div>
                                <span>Poids 4 : </span><input type="number" name="p4"/>
                            </div>
                        </div>
                        <button type="submit">Valider</button>
                    </form>
                </React.Fragment>
            }
            <br/>&#160;

        </div>
    );
}

export default Balance