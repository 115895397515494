import React from 'react';


function ListeEquipageTable() {
    const headerStyle = {
        borderBottom: 'solid 3px red',
        background: 'aliceblue',
        color: 'black',
        fontWeight: 'bold',
    }
    const cellStyle = {
        padding: '10px',
        border: 'solid 1px gray',
        background: 'papayawhip',
    }
    const data = React.useMemo(() => [{
        id: 1,
        name: 'Isaline Cornil',
        country: 'France',
        oldship: 'Revenge',
        age: '35',
        role: 'Capitaine',
        note: 'Élucide tous les mystères',
    }, {
        id: 2,
        name: 'Edward Law',
        country: 'Irlande',
        oldship: 'Revenge',
        age: '43',
        role: 'Second - premier lieutenant',
        note: '',
    }, {
        id: 3,
        name: 'Esteban Espinosa',
        country: 'Espagne',
        oldship: 'OldMaria',
        age: '41',
        role: 'Canonnier - second lieutenant',
        note: 'A une jambe de bois',
    }, {
        id: 4,
        name: 'Arold Reinards',
        country: 'Angleterre',
        oldship: 'Cralik',
        age: '26',
        role: 'Maître d\'équipage',
        note: 'Une heure de sommeil par nuit lui suffisent',
    }, {
        id: 5,
        name: 'Jose Vega',
        country: 'Espagne',
        oldship: 'M.S. Destiny',
        age: '33',
        role: 'Pilote',
        note: '',
    }, {
        id: 6,
        name: 'Laars Nolsen',
        country: 'Danois',
        oldship: 'Cralik',
        age: '20',
        role: 'Timonier',
        note: '',
    }, {
        id: 7,
        name: 'Erik Roostendal',
        country: 'Danois',
        oldship: 'Cralik',
        age: '32',
        role: 'Maître cannonier',
        note: 'À un problème d\'alcool',
    }, {
        id: 8,
        name: 'Juan Vargas',
        country: 'Espagne',
        oldship: 'OldMaria',
        age: '29',
        role: 'Maître Charpentier',
        note: '',
    }, {
        id: 9,
        name: 'Jesus Fallecido',
        country: 'Espagne',
        oldship: 'Cralik',
        age: '27',
        role: 'Maître Calfat',
        note: '',
    }, {
        id: 10,
        name: 'John Segwick',
        country: 'Angleterre',
        oldship: '',
        age: '19',
        role: 'Maître Voilier',
        note: '',
    }, {
        id: 11,
        name: 'Luis Paratugo',
        country: 'Portugal',
        oldship: 'M.S. Destiny',
        age: '39',
        role: 'Chirurgien',
        note: '',
    }, {
        id: 12,
        name: 'Paulo Estrella',
        country: 'Espagne',
        oldship: 'M.S. Destiny',
        age: '30',
        role: 'Cambusier',
        note: '',
    }, {
        id: 13,
        name: 'Jean Petit',
        country: 'France',
        oldship: '',
        age: '23',
        role: 'Quartier maître',
        note: '',
    }, {
        id: 14,
        name: 'Raymond Fouchet',
        country: 'France',
        oldship: 'M.S. Destiny',
        age: '29',
        role: 'Cuisinier',
        note: '',
    }, {
        id: 15,
        name: 'Fernando Raley',
        country: 'Angleterre',
        oldship: 'OldMaria',
        age: '31',
        role: 'Cuisinier',
        note: 'Demi frère de Luis Paratugo',
    }, {
        id: 16,
        name: 'Rackam Rilsen',
        country: 'Danois',
        oldship: '',
        age: '28',
        role: 'Cuisinier',
        note: '',
    }, {
        id: 17,
        name: 'Pierre Jacquet',
        country: 'France',
        oldship: 'Revenge',
        age: '36',
        role: 'Matelot',
        note: 'Honnête et droit, il t\'as suivi dans toutes tes aventures depuis l\'enfance',
    }, {
        id: 18,
        name: 'Octavio Hemingway',
        country: 'Angleterre',
        oldship: 'OldMaria',
        age: '17',
        role: 'Matelot',
        note: '',
    }, {
        id: 19,
        name: 'Archibald Jones',
        country: 'Angleterre',
        oldship: 'M.S. Destiny',
        age: '19',
        role: 'Matelot',
        note: '',
    }, {
        id: 20,
        name: 'Ronald Young',
        country: 'Angleterre',
        oldship: 'Cralik',
        age: '32',
        role: 'Matelot',
        note: '',
    }, {
        id: 21,
        name: 'Ferndinand Riviera',
        country: 'Espagne',
        oldship: 'Cralik',
        age: '41',
        role: 'Matelot',
        note: 'Un oeil en moins',
    }, {
        id: 22,
        name: 'Bryan Kitchen',
        country: 'Angleterre',
        oldship: '',
        age: '14',
        role: 'Matelot',
        note: '',
    }, {
        id: 23,
        name: 'Ruppert Plier',
        country: 'Angleterre',
        oldship: 'M.S. Destiny',
        age: '18',
        role: 'Matelot',
        note: '',
    }, {
        id: 27,
        name: 'Enrico Ademassias',
        country: 'Portugal',
        oldship: '',
        age: '34',
        role: 'Matelot',
        note: '',
    }, {
        id: 24,
        name: 'Cheek Mulder',
        country: 'Angleterre',
        oldship: 'M.S. Destiny',
        age: '23',
        role: 'Gabier',
        note: '',
    }, {
        id: 25,
        name: 'Oleg Nolsen',
        country: 'Danois',
        oldship: 'OldMaria',
        age: '20',
        role: 'Gabier',
        note: '',
    }, {
        id: 26,
        name: 'Sebastien Molas',
        country: 'France',
        oldship: 'OldMaria',
        age: '23',
        role: 'Gabier',
        note: '',
    }, {
        id: 27,
        name: 'Raul Proust',
        country: 'Espagne',
        oldship: 'Cralik',
        age: '28',
        role: 'Gabier',
        note: 'Dit "Le poulpe" à cause de ses cheveux',
    },{
        id: 27,
        name: 'Basil Cervesa',
        country: 'Portugal',
        oldship: 'M.S. Destiny',
        age: '23',
        role: 'Gabier',
        note: '',
    },{
        id: 27,
        name: 'Andy Dimoiwee',
        country: 'Angleterre',
        oldship: 'OldMaria',
        age: '36',
        role: 'Gabier',
        note: '',
    },
    ], [])
    return (
        <table style={{border: 'solid 1px blue', overflow: 'auto'}}>
            <thead>

            <tr>
                <th style={headerStyle}>Nom</th>
                <th style={headerStyle}>Pays d'origine</th>
                <th style={headerStyle}>Ancien navire</th>
                <th style={headerStyle}>Age</th>
                <th style={headerStyle}>Role</th>
                <th style={headerStyle}>Note</th>
            </tr>
            </thead>
            <tbody>
            {data.map((it) => (
                <tr key={it.id}>
                    <td style={cellStyle}>{it.name}</td>
                    <td style={cellStyle}>{it.country}</td>
                    <td style={cellStyle}>{it.oldship}</td>
                    <td style={cellStyle}>{it.age}</td>
                    <td style={cellStyle}>{it.role}</td>
                    <td style={cellStyle}>{it.note}</td>
                </tr>
            ))
            }
            </tbody>
        </table>

    )

}

function ListeEquipage() {
    return (
        <div className="written-page">
            <h2>
                Chap. II : Liste de l'équipage
            </h2>
            <div style={{overflow: "auto", width: "100vw"}}>
                <ListeEquipageTable/>
            </div>
        </div>
    );
}

export default ListeEquipage