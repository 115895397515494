import React, {useEffect} from 'react';
import './intro.css'
import {Link} from "react-router-dom";
import {useDispatch} from "react-redux";
import {addToInventory} from "../../features/progress";

function Intro() {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(addToInventory("piece_tresor"))
    }, [dispatch])

    return (
        <div className="written-page">
            <h2>
                Intro
            </h2>
            <p>
                Tu penses être dans un train entre Toulon et Paris. En réalité, tu embarques pour un voyage bien plus
                lointain et dépaysant.
            </p>
            <p>
                Je te propose de devenir la capitaine de mon navire et de partir en mission pour notre communauté.
            </p>
            <p>
                Nous sommes le <i>13 janvier 1863</i>, il y a 10 ans, jour pour jour, mon ami le missionnaire <i>Daniel
                Everett</i>&#160;
                entreprenait un périple depuis notre petite ville de Nassau pour s'enfoncer en Amazonie. <br/>
                Lui aussi avait pris mon bateau. Et au terme de son périple, il avait découvert le trésor de
                Coyolxauhqui.<br/>
                Il en avait rapporté une pièce en or.<br/>
            </p>
            <p>
                Ce qu'il ne savait pas c'est qu'il ne rapportait pas que cette pièce avec lui, il rapportait aussi
                la <b>Malédiction</b> de Coyolxauhqui.<br/>
            </p>
            <p>
                À son retour Daniel semblait être devenu fou, il criait sur les toits qu'il n'aurait jamais
                dû voler cette pièce.<br/>
                Qu'elle causerait sa perte ainsi que celle de tout les habitants de Naussau.<br/>
                Il disait que son avidité avait <i>"embué sa vision d'un voile blanc"</i> et qu'il en paierait bientôt les
                conséquences.
            </p>
            <p>
                Je ne faisais pas trop attention à ses élucubrations, jusqu'à sa mort...
                Survenue, de façon soudaine, inexpliquée et qui à laissé sur ses yeux un voile laiteux blanc.<br/>
                S'en est suivi, à chaque nouvelle Lune suivante une mort d'un habitant de Naussau, dans les mêmes circonstances.
            </p>
            <p>
                Je ne suis pas encore totalement sûr qu'il faille croire à cette Malédication, mais dans le doute, je me dis qu'il serait plus sage de rapporter cette pièce à sa place.<br/>
                C'est la mission que je te confie.<br/>
            </p>
            <p>
                Il est temps pour toi de faire tes bagages avant d'embarquer sur le navire.
            </p>

            <Link
                className="App-link"
                to="/chap1/bagagespackstart"
            >
                Faire mes bagages &#8250;
            </Link>
            <br/>&#160;
        </div>
    );
}

export default Intro;