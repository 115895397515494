import {combineReducers} from "redux";
import mapuceReducer from "./mapuce/feature"
import malauryReducer from "./malaury/feature"
import marocanarReducer from "./marocanar/feature"


export default combineReducers({
    mapuce: mapuceReducer,
    malaury: malauryReducer,
    marocanar: marocanarReducer,
})

