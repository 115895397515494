import {Route, Switch} from "react-router-dom";
import Macapa from "./macapa";
import Mapuce from "./mapuce";
import Malaury from "./malaury";
import Marocanar from "./marocanar";

const Chap3Routes = () => (
    <Switch>
        <Route path='/chap3/macapa' component={Macapa}/>
        <Route path='/chap3/mapuce' component={Mapuce}/>
        <Route path='/chap3/malaury' component={Malaury}/>
        <Route path='/chap3/marocanar' component={Marocanar}/>
    </Switch>
)
export default Chap3Routes