import {createSelector, createSlice} from '@reduxjs/toolkit'

export const balanceSlice = createSlice({
    name: 'balance',
    initialState: {
        solved: false,
        message: ""
    },
    reducers: {
        proposeSolution: (state, action) => {
            let values = action.payload
            let invalidItem = values.filter((it) => isNaN(it) || it < 0)
            state.message = ""
            state.solved = false
            if (invalidItem.length > 0) {
                state.message = "L'une des valeurs est invalide ! Rentre uniquement des nombres entiers positifs"
                return
            }
            values.sort((a, b) => a - b)
            const validResult = [1, 3, 9, 27]
            const ok = (values.length === validResult.length && values.every((value, index) => value === validResult[index]))
            if(ok){
                state.solved = true
            }else{
                state.message = "Ça ne te permet pas de tout mesurer"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution} = balanceSlice.actions

const selectSelf = (state) => state.chap1.balance
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default balanceSlice.reducer