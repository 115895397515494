import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './mapuce.css'
import macapa_central from "./macapa_central.jpg"

import {proposeSolution, selectIsSolved, selectMessage} from "./feature";
import {Link} from "react-router-dom";

function Mapuce() {
    const dispatch = useDispatch()
    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        const form = event.target
        dispatch(proposeSolution({
            exterior: form.exterior.value,
            smallplots: form.smallplots.value,
            holesposition: form.holesposition.value,
            highesthole: form.highesthole.value,
            lowesthole: form.lowesthole.value,
        }))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)
    return (
        <div className="written-page Page-Mapuce">
            <h2>
                Chap. III : Mapuce
            </h2>
            <p>
                Goglus Mapuce veut bien travailler pour toi mais tu dois d'abord l'aider à dessiner les plans du futur
                monument qui doit marquer l'endroit où le monde se sépare en deux.<br/>
                Pour se faire, tu peux déjà t'aider de Goglus Mapuce. <br/>
                Il a les plans de la ville et peut t'aider à voir chaque rue de la ville dans le futur.<br/>
                <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/Macap%C3%A1,+Amap%C3%A1,+Br%C3%A9sil/@0.1019439,-51.1670718,12z/data=!3m1!4b1!4m5!3m4!1s0x8d61e5e06b4b1a37:0x56357df330df0f97!8m2!3d0.0355715!4d-51.0705364?hl=fr">
                    Par ici pour explorer Macapa avec Mapuce
                </a>
            </p>

            {message ? <p className="error">{message}</p> : null}
            {solved ? <p>
                    Bravo ! Tu as conçu le monument parfait. <br/>
                    Voilà ce à quoi il ressemblera dans le futur <br/>
                    <img src={macapa_central} alt="Macapa monument" style={{width: '100%'}}/>
                    <br/>
                    <br/>
                    <Link
                        className="App-link"
                        to="/chap3/macapa">Retour à Macapa
                    </Link>
                </p> :
                <form onSubmit={onFormSubmit} method="GET">
                    <label>
                        Forme exterieure principale :
                        <select name="exterior">
                            <option value="obelisque">Obelisque</option>
                            <option value="pyramide">Pyramide</option>
                            <option value="rectangle">Rectangle</option>
                            <option value="cercle">Cercle</option>
                            <option value="cube">Cube</option>
                        </select>
                    </label>
                    <label>
                        Deux petits plots au sol (de chaque côté de la ligne) :
                        <select name="smallplots">
                            <option value="obelisque">Obélisque</option>
                            <option value="pyramide">Pyramide</option>
                            <option value="rectangle">Rectangle</option>
                            <option value="cercle">Cercle</option>
                            <option value="cube">Cube</option>
                        </select>
                    </label>
                    <label>
                        Position des trous :
                        <select name="holesposition">
                            <option value="bottom">Bas</option>
                            <option value="middle">Milieu</option>
                            <option value="top">Haut</option>
                        </select>
                    </label>
                    <label>
                        Forme du trou le plus haut :
                        <select name="highesthole">
                            <option value="cercle">Cercle</option>
                            <option value="rectangle">Rectangle</option>
                            <option value="triangle">Triangle</option>
                            <option value="triangle">Rose des vents</option>
                        </select>
                    </label>
                    <label>
                        Forme du trou le plus bas :
                        <select name="lowesthole">
                            <option value="cercle">Cercle</option>
                            <option value="rectangle">Rectangle</option>
                            <option value="triangle">Triangle</option>
                            <option value="triangle">Rose des vents</option>
                        </select>
                    </label>


                    <button type="submit">Valider</button>
                </form>}

        </div>
    );
}

export default Mapuce