import BagagesPackStart from "./bagagespackstart";
import {Route, Switch} from "react-router-dom";
import PieceBagages from "./piece";
import Balance from "./balance";
import Armoire from "./armoire";
import Carnet from "./carnet";
import Coffre from "./coffre";
import Epee from "./epee";

const Chap1Routes = () => (
    <Switch>
        <Route path='/chap1/bagagespackstart' component={BagagesPackStart}/>
        <Route path='/chap1/piece' component={PieceBagages}/>
        <Route path='/chap1/balance' component={Balance}/>
        <Route path='/chap1/coffre' component={Coffre}/>
        <Route path='/chap1/epee' component={Epee}/>
        <Route path='/chap1/armoire' component={Armoire}/>
        <Route path='/chap1/carnet' component={Carnet}/>
    </Switch>
)
export default Chap1Routes