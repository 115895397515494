import './App.css';
import {Route, Switch, useHistory, withRouter} from "react-router-dom";
import Home from "./pages/home";
import Intro from "./pages/intro";
import React, {useCallback, useEffect} from "react";
import {loadPage, selectHasNavigation} from "./features/progress";
import {closeNavigation, selectNavigationIsOpen, toggleOpen} from "./features/navigation-menu";
import MainNav from "./widgets/main-nav";
import {useDispatch, useSelector} from "react-redux";
import Chap1Routes from "./pages/chap1/routes";
import NotebookRoutes from "./pages/notebook/routes";
import Chap2Routes from "./pages/chap2/routes";
import Chap3Routes from "./pages/chap3/routes";
import Chap4Routes from "./pages/chap4/routes";


function App() {
    const history = useHistory()
    const dispatch = useDispatch()
    const hasInventory = useSelector(selectHasNavigation)
    const navigationIsOpen = useSelector(selectNavigationIsOpen)

    useEffect(() => {
        return history.listen((location) => {
            dispatch(loadPage(location.pathname))
            console.log(`You changed the page to: ${location.pathname}`)
            dispatch(closeNavigation())
        })
    }, [history, dispatch])

    const toggleNavigation = useCallback(
        () => dispatch(toggleOpen()),
        [dispatch]
    )


    return (
        <div className="App">
            <Switch>
                <Route path="/intro">
                    <Intro/>
                </Route>
                <Route path="/chap1" component={Chap1Routes} />
                <Route path="/chap2" component={Chap2Routes} />
                <Route path="/chap3" component={Chap3Routes} />
                <Route path="/chap4" component={Chap4Routes} />
                <Route path="/notebook" component={NotebookRoutes} />
                <Route path="/">
                    <Home/>
                </Route>
            </Switch>
            {navigationIsOpen ? <MainNav/> : null}
            {hasInventory ?
                <button className="App-inventory-btn" onClick={toggleNavigation}/> : null}
        </div>
    );
}

export default withRouter(App)
