import {Route, Switch} from "react-router-dom";
import VideoDepart from "./videodepart";
import ListeEquipage from "./listeequipage";
import Meurtre from "./meurtre";
import Interrogatoire from "./interrogatoire";
import Requins from "./requins";
import Arrivee from "./arrivee";

const Chap2Routes = () => (
    <Switch>
        <Route path='/chap2/videodepart' component={VideoDepart}/>
        <Route path='/chap2/listeequipage' component={ListeEquipage}/>
        <Route path='/chap2/meurtre' component={Meurtre}/>
        <Route path='/chap2/interrogatoire' component={Interrogatoire}/>
        <Route path='/chap2/requins' component={Requins}/>
        <Route path='/chap2/arrivee' component={Arrivee}/>
    </Switch>
)
export default Chap2Routes