import React from 'react';
import './arrivee.css'
import {Link} from "react-router-dom";

function Arrivee() {
    return (
        <div className="written-page Page-Arrivee">
            <h2>
                Chap. II : Sur les côtes
            </h2>
            <p>
                Une fois le tueur en série jeté aux requins, le reste de la traversée se déroule sans encombres. John
                Segwick avait aussi quelques notions de charpentier et il a donc réussi, avec le reste de l'équipage, à
                réparer le mât de misaine. Quelques semaines passent et les côtes du Brésil se dessinent au loin.
            </p>
            <p>
                Vous accostez enfin à Macapa. Il est temps de changer de bateau pour remonter l'Amazone, de refaire les
                provisions et de monter une équipe pour s'enfoncer dans le territoire des Piraha. <br/>
                Les membres les plus fidèles de ton équipage vont t'accompagner. Il était prévu que Vargas soit votre
                pilote dans les méandres de ce fleuve immense. Mais il avait probablement menti sur ça aussi !
            </p>
            <Link
                className="App-link"
                to="/chap3/macapa"
            >
                ENTRER À MACAPA
            </Link>
        </div>
    );
}

export default Arrivee