import React, {useCallback} from 'react';
import "./requins.css"
import {useDispatch, useSelector} from "react-redux";
import {proposeSolution, selectIsSolved, selectKilled, selectMessage} from "./feature";
import planche from "./planche.jpg"
import {Link} from "react-router-dom";

function Requins() {
    const dispatch = useDispatch()
    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        dispatch(proposeSolution(event.target.name.value))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)
    const killed = useSelector(selectKilled)

    return (
        <div className="written-page Requins-Page">
            <h2>
                Chap. II : Aux requins
            </h2>
            <p>
                Désigne qui jeter au requins
            </p>
            {
                killed.length > 0 ? (<React.Fragment>
                    <p>Tu as jeté aux requins</p>
                    <ul>
                        {killed.map((it) => (
                            <li key={it}>{it}</li>
                        ))}
                    </ul>
                </React.Fragment>) : null
            }
            {message ? <p className="error">{message}</p> : null}
            {solved ?

                <React.Fragment>
                    <div style={{fontSize: "21px", padding: "15px"}}>
                        Bravo tu as résolu ce meurtre ! <br/>
                        C'était bien Vargas. Son histoire de poissons d'eau douce ne tenait pas la route. Il était sur
                        le
                        OldMaria. Il ne faisait pas partie du cercle de joueurs Anglais.
                    </div>
                    <p>
                        D'autres membres d'équipage t'ont menti pendant l'enquête, mais pour que le navire arrive à bon
                        port, tu décides de seulement les garder à l'oeil.<br/>
                        N'hésite pas à m'en parler si tu veux confirmer ton intuition sur ce qui s'est vraiment passé ce
                        matin là !
                    </p>
                    <div style={{padding: "15px"}}>

                    <Link
                        className="App-link"
                        to="/chap2/arrivee"
                    >
                        POURSUIVRE LE VOYAGE
                    </Link>
                    </div>
                </React.Fragment>
                :
                <React.Fragment>
                    <p>
                        Qui pousses-tu ?
                    </p>
                    <form onSubmit={onFormSubmit} method="GET">
                        <input name="name"/>
                        <button type="submit">Valider</button>
                    </form>
                    <div className="text-center">
                        <img src={planche} alt="Planche"/>
                    </div>
                </React.Fragment>
            }
            <br/>&#160;

        </div>
    );
}

export default Requins