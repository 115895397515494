import "./carnet.css"
import {useDispatch, useSelector} from "react-redux";
import React, {useCallback} from "react";
import {addToInventory, selectHasInInventory} from "../../../features/progress";
import carnet from "./carnet.png"
import {Link, withRouter} from "react-router-dom";
import {proposeSolution, selectIsSolved, selectMessage} from "./feature";

function Carnet({history}) {
    const dispatch = useDispatch()
    const collectCarnet = useCallback(() => {
        dispatch(addToInventory("carnet"))
        history.push('/chap1/carnet');
    }, [history, dispatch])

    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        dispatch(proposeSolution(event.target.response.value))
    }, [dispatch])
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)

    const hasCarnet = useSelector(selectHasInInventory("carnet"))
    return (
        <div className="written-page Armoire-Page">
            <h2>
                Chap. I : Conseil du Carnet
            </h2>
            {!hasCarnet ?
                <React.Fragment>

                    <div className="text-center">
                        <img onClick={collectCarnet} src={carnet} alt="Carnet"/>
                    </div>
                    <p>Bravo, tu peux maintenant récupérer le carnet en cliquant dessus !</p>
                </React.Fragment> :
                <React.Fragment>
                    <p>
                        Le carnet de Daniel Everett est maintenant dans ton inventaire ! <br/>
                        Il contient plein d'informations précieuses pour ton voyage. <br/>
                    </p>
                    {
                        solved ?
                            <div style={{fontSize: "21px", marginTop: "15px"}}>
                                Bravo tu as récupéré le Sextant ! <br/>
                                <Link to="/chap1/piece">Retourner à la piece des bagages</Link>
                            </div> :
                            <React.Fragment>
                                <p>
                                    Le carnet te suggère également d'emporter un objet, lequel ?<br/>
                                    <b>Tu peux désormais le feuilleter depuis ton inventaire</b><br/>
                                    Reviens ici pour me dire ce dont tu as besoin lorsque tu auras la réponse.
                                </p>
                                {
                                    message ?
                                        <p className="error">{message}</p> :
                                        null
                                }
                                <form onSubmit={onFormSubmit}>
                                    <input name="response" type="text"/>
                                    <button>Valider</button>
                                </form>
                            </React.Fragment>
                    }
                    <br/>
                    &#160;
                </React.Fragment>
            }

        </div>
    )
}

export default withRouter(Carnet)