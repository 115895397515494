import {createSelector, createSlice} from '@reduxjs/toolkit'

export const marocanarSlice = createSlice({
    name: 'marocanar',
    initialState: {
        solved: false,
        message: "",
    },
    reducers: {
        proposeSolution: (state, action) => {
            let {
                rio1,
                rio2,
                rio3,
            } = action.payload
            state.message = ""
            let ok = true
            if(!rio1.toLowerCase().includes("madeira")) {
                ok = false
            }
            if(!rio2.toLowerCase().includes("marmelos")) {
                ok = false
            }
            if(!rio3.toLowerCase().includes("maici")) {
                ok = false
            }

            if (ok) {
                state.solved = true
            } else {
                state.message = "Ce n'est pas le bon chemin"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution} = marocanarSlice.actions

const selectSelf = (state) => state.chap3.marocanar
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default marocanarSlice.reducer