import {createSelector, createSlice} from '@reduxjs/toolkit'

const ENIGMES = [
    {
        solution: "courant",
        mots: [
            "pensée",
            "électrique",
            "marin",
            "air",
            "prise"
        ],
        initial: 2
    },
    {
        solution: "radio",
        mots: [
            "silence",
            "reveil",
            "station",
            "poste",
            "fréquence",
            "antenne"
        ],
        initial: 3
    },
    {
        solution: "puce",
        mots: [
            "saut",
            "oreille",
            "chien",
            "insecte",
            "électronique",
            "carte"
        ],
        initial: 2
    },
    {
        solution: "trou",
        mots: [
            "golf",
            "mémoire",
            "serrure",
            "souris",
            "noir",
            "eau"
        ],
        initial: 2
    },
    {
        solution: "part",
        mots: [
            "marché",
            "gâteau",
            "portion",
            "faire",
            "partage",
            "tranche"
        ],
        initial: 3
    },
    {
        solution: "clou",
        mots: [
            "spectacle",
            "cavalier",
            "marteau",
            "girofle",
            "pointe",
            "quincaillerie"
        ],
        initial: 2
    }
]

export const malaurySlice = createSlice({
    name: 'malaury',
    initialState: {
        alreadySolved: [],
        helpRequested: 0,
        currentEnigme: 0,
        currentHelp: 0,
        message: "",
    },
    reducers: {
        proposeSolution: (state, action) => {
            let {
                proposal,
            } = action.payload
            state.message = ""
            const enigmeIdx = state.currentEnigme || 0
            const enigme = ENIGMES[enigmeIdx]
            const currentHelp = state.currentHelp || enigme.initial
            let ok = proposal.toLowerCase().includes(enigme.solution)

            if (ok) {
                if(!state.alreadySolved){
                    state.alreadySolved = []
                }
                state.alreadySolved.push({
                    solution: enigme.solution,
                    extraHelp:  currentHelp - enigme.initial
                })
                state.currentHelp = 0
                state.currentEnigme = enigmeIdx + 1
            } else {
                state.message = `${proposal} n'est pas la solution`
            }
        },
        askHelp: (state, action) => {
            const enigmeIdx = state.currentEnigme || 0
            const enigme = ENIGMES[enigmeIdx]
            const currentHelp = state.currentHelp || enigme.initial
            state.currentHelp = currentHelp + 1
        }
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution, askHelp} = malaurySlice.actions

const selectSelf = (state) => state.chap3.malaury
export const selectCurrentEnigmeIdx = createSelector(selectSelf, state => state.currentEnigme || 0 )
export const selectCurrentEnigme = createSelector(selectSelf, selectCurrentEnigmeIdx, (state, enigmeIdx) => {
    if(enigmeIdx >= ENIGMES.length){
        return {}
    }
    const enigme = ENIGMES[enigmeIdx]
    const wordsPos = (state.currentHelp||enigme.initial)
    const hasMore = wordsPos < enigme.mots.length
    return {
        mots: enigme.mots.slice(0, wordsPos),
        idx: enigmeIdx,
        hasMore
    }
})
export const selectSolvedLog = createSelector(selectSelf, state => state.alreadySolved || [])
export const selectIsSolved = createSelector(selectCurrentEnigmeIdx, currentEnigmeIdx => currentEnigmeIdx >= ENIGMES.length )
export const selectMessage = createSelector(selectSelf, state => state.message)
export const MAX_SCORE = ENIGMES.reduce((p, it)=> p + (it.mots.length - it.initial), 0)

export default malaurySlice.reducer