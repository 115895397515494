import {createSelector, createSlice} from '@reduxjs/toolkit'

export const navigationMenuSlice = createSlice({
  name: 'navmenu',
  initialState: {
  },
  reducers: {
    toggleOpen: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.open = !state.open
    },
    closeNavigation: (state) => {
      state.open = false
    },
    openNavigation: (state) => {
      state.open = true
    }
  },
})

// Action creators are generated for each case reducer function
export const { toggleOpen, closeNavigation, openNavigation } = navigationMenuSlice.actions

const selectSelf = state => state.navmenu
export const selectNavigationIsOpen = createSelector(selectSelf, state => state.open)

export default navigationMenuSlice.reducer
