import React from 'react';
import './bagagespackstart.css'
import {Link} from "react-router-dom";
import porte from "./porte_pirate.png";

function BagagesPackStart() {
    return (
        <div className="written-page">
            <h2>
                Chap. I : Prête ?
            </h2>
            <p>
                À partir de maintenant, tu as à ta disposition le gouvernail en haut à droite qui te permettra de te
                retrouver dans tes tâches en cours.
            </p>
            <p>
                Il te permettra également de retrouver ton inventaire.
            </p>
            <p>
                Clique sur la porte pour accéder à la pièce dans laquelle tu trouveras ce dont tu auras besoin pour partir à l'aventure
            </p>
            <Link to="/chap1/piece">
                <img src={porte} alt="Porte"/>
            </Link>
            {/*<p>*/}
            {/*    Consulte le carnet d'Everett et indique ci-dessous ce que tu souhaites embarquer avec toi au regard des*/}
            {/*    informations contenues dans le carnet.<br/>*/}
            {/*    <i>Renseigne le nom d'un objet puis essaie de le valider</i>*/}
            {/*</p>*/}
            {/*<div className="form-chap1-bagages">*/}
            {/*    <input type="text" placeholder="Nom de l'objet" name="chap1-bagages"/>*/}
            {/*    <button>OK</button>*/}
            {/*</div>*/}
        </div>
    );
}

export default BagagesPackStart