import React from 'react';
import date_day from './date_day.png'
import date_month from './date_month.png'
import date_year from './date_year.png'

function Commencement() {
    return (
        <div className="Carnet-Page">
            <h2>
                Au commencement
            </h2>
            <p>
                Nous sommes le
                <img className="cistercien" src={date_day} alt="day"/> jour du mois
                <img className="cistercien" src={date_month} alt="month"/> de l'année
                <img className="cistercien" src={date_year} alt="year"/>&#160;,
                aujourd'hui, j'embarque vers une aventure au coeur du territoire des Pirahaas
                afin d'aller étudier leurs coutumes, leur language et leurs richesses.
            </p>
            <p>
                Je suis impatient de partir, et de consigner ici toutes mes découvertes.<br/>
                Je débute mon voyage depuis Naussau, après avoir recruté quelques pirates en mal d'aventures. <br/>
                Mon entreprise n'est pas d'illégale cependant, mais le voyage que j'entreprend nécessite des gens avec un grand sens
                de l'aventure. <br/>
                La république qu'ils ont établie ici me laisse penser qu'ils sont aussi
                très attachés à une certaine vision de la démocratie. Certes ils n'ont pas l'habitude de faire de quartier, mais l'équipe que j'ai monté me
                suivra jusqu'au bout du monde... et c'est précisement là que je pense les mener...
            </p>
            <div className="page-indicator">Page 1</div>
        </div>
    );
}

export default Commencement