import React from 'react';

function Pirahas() {
    return (
        <div className="Carnet-Page">
            <h2>
                Pirahas
            </h2>
            <p>
                Le peuple pirahã vit le long du Maici au Brésil. Ils sont divisés en plusieurs communautés principales,
                dont l'une est située près de l'embouchure du fleuve Posto Novo. Ces différentes localisations sont à
                l'origine de certaines différences entre les deux communautés : la communauté habitant en aval ayant
                davantage de contact avec les populations brésiliennes.
            </p>
            <p>
                Parmi les autres villages se trouvent Coatá, Porto Novo, Posto Novo, Xagíopai (à sept jours de Posto
                Novo en canoë), et Toitoi. Je peux également citer les villages de Forquilha Grande et Piquia.
            </p>
            <p>
                Leur langue (le pirahã) est un des piliers de leur culture et de leur identité. C'est une langue qu'ils
                peuvent siffler, et c'est d'ailleurs de cette manière qu'ils communiquent lors de leurs chasses dans la
                jungle. Cette langue et cette culture ont chacune des caractéristiques uniques dont voici quelques
                exemples :
            </p>
            <p>
                D'après ce que les Pirahãs m'ont dit, leur culture se base sur les expériences vécues par
                les individus au cours de leur vie, et ne remonte pas plus loin. Il n'y a donc pas d'Histoire, au-delà
                de la mémoire des vivants.
            </p>
            <p>
                Cette langue semble n'avoir aucune proposition relative, ni récursivité grammaticale, mais cela n'est
                pas encore clarifié.<br/>
                Ses sept consonnes et trois voyelles en font la langue possédant le moins de phonèmes au monde.<br/>
                Cette culture a le système de parenté le plus basique connu, les relations ne dépassant pas le cadre de
                la fratrie.<br/>
                Ils ne comptent que jusqu'à deux et n'ont pas de vocabulaire pour décrire les nombres. D'après les
                experiences que j'ai pu mener, ils sont incapables d'apprendre des notions de calcul.<br/>
                Je soupçonne que l'ensemble des pronoms de leur langue, qui est également la plus simple des langues
                connues, a été récemment emprunté au Tupi-guarani, et que le Pirahã n'en possédait même pas auparavant.<br/>
                Il existe une théorie contestée selon laquelle il n'y aurait pas chez eux de terminologie des couleurs.<br/>
                Il n'y aurait pas de racine spécifique pour les mots relatifs aux couleurs, qui puisse être identifiée.<br/>
                Tous les mots ayant trait à la couleur qui ont pu être recueillis sont tous des mots composés comme
                biisai, « qui a la couleur du sanga ».<br/>
                L'art est très peu présent dans leur culture, constitué principalement de colliers et de figurines, de
                facture assez grossière, destinés au départ à éloigner les mauvais esprits.
            </p>
            <p>
                Les Pirahãs font de courtes siestes allant de quinze minutes à deux heures, le jour comme la nuit, mais
                dorment rarement une nuit entière. Ils sont souvent affamés, non pas par manque de nourriture, mais par
                envie de s'endurcir (tigisái).

            </p>
            <div className="page-indicator">Page 4</div>
        </div>
    );
}

export default Pirahas