import {createSelector, createSlice} from '@reduxjs/toolkit'

const COLORS = {}
COLORS["easy"] = {
    "NOIR": "#000",
    "ROUGE": "#ee1d1d",
    "BLEU": "#0b9ce3",
    "VERT": "#2fe52f",
}
COLORS["medium"] = Object.assign({
    "ORANGE": "#ffa200",
    "JAUNE": "#eae41b",
    "VIOLET": "#a600ff",
}, COLORS["easy"])

COLORS["nightmare"] = Object.assign({
    "ROSE": "#ff00d5",
    "GRIS": "#a7a7a7",
}, COLORS["medium"])


const TIME_BY_DIFFICULTY = {
    "easy": 3,
    "medium": 3,
    "nightmare": 2
}

const WORDS_BY_DIFFICULTY = {
    "easy": 4,
    "medium": 6,
    "nightmare": 10
}

const MULTIPLIER_BY_DIFFICULTY = {
    "easy": 10,
    "medium": 30,
    "nightmare": 100
}

export const REQUIRED_SCORE = 180

const resetGameState = (state) => {
    state.started = false
    state.currentWord = 0
    state.solving = 0
    state.proposedSolved = []
    state.guessWords = []
    state.guessColors = []
    state.validity = []
    state.countdownMax = 3
}
const computeScore = (validity, difficulty) => {
    return 1 + validity.reduce((prev, c) => (c ? prev + 1 : prev), 0) * MULTIPLIER_BY_DIFFICULTY[difficulty]
}

const randomPick = (listLength) => (
    Math.min(Math.floor(Math.random() * listLength), listLength - 1)
)

const pickSafeRandom = (wc, lastRandom) => {
    let random = randomPick(wc)
    if (random === lastRandom) {
        random = (wc - 1) - lastRandom
    }
    return random;
}

export const epeeSlice = createSlice({
    name: 'epee',
    initialState: {
        started: false,
        showCountdown: false,
        difficulty: "easy",
        countdown: 5,
        countdownMax: 5,
        currentWord: 0,
        guessWords: [],
        guessColors: [],
        solving: false,
        score: 0,
        proposedSolved: [],
        validity: [],
    },
    reducers: {
        startGame: (state, action) => {
            let {difficulty} = action.payload
            state.difficulty = difficulty
            state.started = true
            const guessWords = []
            const guessColors = []
            let colors = COLORS[difficulty]
            let words_keys = Object.keys(colors)
            let colors_values = Object.values(colors)
            console.log("Pick in ", words_keys, colors_values)
            let wc = words_keys.length
            // Pick colors
            // First item will be same for color and word
            let random = null
            if(difficulty === "easy"){
                random = randomPick(wc)
                console.log("pick at index", random)
                guessWords.push(words_keys[random])
                guessColors.push(colors[words_keys[random]])
            }
            let lastRandomClr = random
            let lastRandomWord = random
            let wordsToShow = WORDS_BY_DIFFICULTY[difficulty]
            for (let i = random === null ? 0 : 1; i < wordsToShow; i++) {
                lastRandomWord = pickSafeRandom(wc, lastRandomWord)
                guessWords.push(words_keys[lastRandomWord])
                lastRandomClr = pickSafeRandom(wc, lastRandomClr)
                guessColors.push(colors_values[lastRandomClr])
            }

            console.log("using ", guessColors, guessWords)
            state.guessWords = guessWords
            state.guessColors = guessColors
            state.currentWord = 0
        },
        resetGame: (state, action) => {
            resetGameState(state);
        },
        showCountDown: (state, action) => {
            state.showCountdown = true
            state.solving = false
        },
        hideCountDown: (state, action) => {
            state.showCountdown = false
            state.countdown = 3
            state.currentWord = 0
        },
        countDown: (state, action) => {
            state.countdown -= 1
        },
        nextWord: (state, action) => {
            state.currentWord += 1
            let wordsToShow = WORDS_BY_DIFFICULTY[state.difficulty || "easy"]
            if (state.currentWord > wordsToShow) {
                state.started = false
            }
        },
        startSolving: (state, action) => {
            state.solving = true
            state.proposedSolved = []
            state.validity = []
        },
        proposeSolution: (state, action) => {
            const solvingIdx = state.proposedSolved.length
            const colors = COLORS[state.difficulty || "easy"]
            state.proposedSolved.push(action.payload)
            let validColor = colors[state.guessWords[solvingIdx]] === action.payload.color
            let validWord = colors[action.payload.word] === state.guessColors[solvingIdx]
            state.validity.push(validColor && validWord)
        },
        collectPoints: (state, action) => {
            state.score += computeScore(state.validity, state.difficulty || "easy")
            resetGameState(state);
        }
    },
})

// Action creators are generated for each case reducer function
export const {
    startGame,
    resetGame,
    showCountDown,
    hideCountDown,
    countDown,
    nextWord,
    startSolving,
    proposeSolution,
    collectPoints
} = epeeSlice.actions

const selectSelf = (state) => state.chap1.epee
export const selectIsStarted = createSelector(selectSelf, state => !!state.started)
export const selectCountDownShown = createSelector(selectSelf, state => !!state.showCountdown)
export const selectCountDownValue = createSelector(selectSelf, state => state.countdown)
export const selectCountDownMax = createSelector(selectSelf, state => state.countdownMax)
export const selectCurrentWord = createSelector(selectSelf, state => state.guessWords && state.guessWords.length > 0 && state.guessWords[state.currentWord])
export const selectCurrentIndex = createSelector(selectSelf, state => state.currentWord + 1)
export const selectCurrentColor = createSelector(selectSelf, state => state.guessColors && state.guessColors.length > 0 && state.guessColors[state.currentWord])
export const selectIsSolving = createSelector(selectSelf, state => state.solving)
export const selectCurrentSolvingIndex = createSelector(selectSelf, state => state.proposedSolved.length + 1)
export const selectProposed = createSelector(selectSelf, state => state.proposedSolved)
export const selectToGuessWords = createSelector(selectSelf, state => state.guessWords)
export const selectToGuessColors = createSelector(selectSelf, state => state.guessColors)
export const selectValidities = createSelector(selectSelf, state => state.validity)
export const selectIsSolved = createSelector(selectSelf, state => (state.score || 0) >= REQUIRED_SCORE)
export const selectScore = createSelector(selectSelf, state => state.score || 0)

// Selectors over difficulty
const selectDifficulty = createSelector(selectSelf, state => state.difficulty || "easy")
export const selectWordsToShow = createSelector(selectDifficulty, difficulty => WORDS_BY_DIFFICULTY[difficulty])
export const selectTimeToShow = createSelector(selectDifficulty, difficulty => TIME_BY_DIFFICULTY[difficulty])
export const selectPossibleWords = createSelector(selectDifficulty, difficulty => Object.keys(COLORS[difficulty]))
export const selectPossibleColors = createSelector(selectDifficulty, difficulty => Object.values(COLORS[difficulty]))

export const selectWonPoints = createSelector(selectSelf, selectDifficulty, (state, difficulty) => {
    return computeScore(state.validity, difficulty)
})

export default epeeSlice.reducer