import crosswords from "./CrosswordISAs.svg";
import React from 'react';
import './crosswords.css'

function Crosswords() {
    return (
        <div className="Crosswords Carnet-Page">
            <h2>
                À Emporter
            </h2>
            <img src={crosswords} className="Crosswords-grid" alt="logo"/>
            <h4>Verticalement</h4>
            <ul>
                <li>1. Plusieurs Lana</li>
                <li>2. Toujours à la fin</li>
                <li>3. Lune</li>
                <li>4. Sookie</li>
                <li>5. Responsabilité de Séléné</li>
                <li>6. Travaux dirigés</li>
                <li>7. Conseiller d'orientation</li>
                <li>8. Note de musique</li>
                <li>9. Raccord de plomberie</li>
                <li>10. Soeur</li>
                <li>11. Prison magique</li>
                <li>12. Paire</li>
                <li>13. Trademark</li>
                <li>14. Porte fil</li>
                <li>15. Note de musique</li>
                <li>16. Muse de l'histoire</li>
                <li>17. Dehors</li>
                <li>18. Mask singer</li>
                <li>19. Patate Américaine</li>
                <li>20. Personne âgée</li>
                <li>21. Mort</li>
            </ul>
            <h4>Horizontalement</h4>
            <ul>
                <li>1. Note de musique</li>
                <li>4. A été</li>
                <li>22. Allumer le feu</li>
                <li>23. Raillée</li>
                <li>24. Zéro</li>
                <li>25. Vient avec Pas</li>
                <li>26. Forgeron</li>
                <li>27. Au régime alimentaire strict</li>
                <li>28. Manières</li>
                <li>29. Connection sans fil</li>
                <li>30. Journal Espagnol</li>
                <li>31. Évalura</li>
                <li>32. Canarias</li>
                <li>33. Longitudinal</li>
                <li>34. Note de musique</li>
                <li>35. Nickel</li>
                <li>36. Ile principale de Wallis</li>
                <li>37. Clown de King</li>
                <li>38. Porte</li>
            </ul>
            <p>Ce qui est entouré forme une chose que tu dois emporter</p>
            <div className="page-indicator">Page 3</div>
        </div>
    );
}

export default Crosswords;