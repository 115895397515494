import {Route, Switch} from "react-router-dom";
import Commencement from "./commencement";
import Crosswords from "./crosswords";
import PlanBateau from "./plan_bateau";
import Pirahas from "./pirahas";
import CryptoCercles from "./cryptocercles";
import CryptoAide from "./cryptoaide";

const NotebookRoutes = () => (
    <Switch>
        <Route path='/notebook/commencement' component={Commencement}/>
        <Route path='/notebook/plan_bateau' component={PlanBateau}/>
        <Route path='/notebook/crosswords' component={Crosswords}/>
        <Route path='/notebook/pirahas' component={Pirahas}/>
        <Route path='/notebook/cryptocercles' component={CryptoCercles}/>
        <Route path='/notebook/helpcryptocercles' component={CryptoAide}/>
    </Switch>
)
export default NotebookRoutes