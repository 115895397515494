import "./armoire.css"
import armoire from "./armoire.png"

function Armoire() {

    return (
        <div className="written-page Armoire-Page">
            <h2>
                Chap. I : Armoire
            </h2>
            <div className="text-center">
                <img src={armoire} alt="Armoire" width="100%"/>
            </div>
            <p>
                Il va falloir faire preuve d'<b>adresse</b> pour accéder à ce <b>carnet</b> sur cette <b>armoire</b>.
            </p>
        </div>
    );
}
export default Armoire