import React from 'react';
import crypto_cercles from './page_crypted_instruction.png'
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

function CryptoCercles() {
    return (
        <div className="Carnet-Page">
            <h2>
                Crypto cercles
            </h2>
            <div style={{width: "100%", "height": "80vh"}}>
                <PinchZoomPan>
                    <img src={crypto_cercles} alt="Crypto cercles"/>
                </PinchZoomPan>
            </div>
            <div className="page-indicator">Page 6</div>
        </div>
    );
}

export default CryptoCercles