import React from 'react';
import plan_bateau from './plan_bateau.png'
import PinchZoomPan from "react-responsive-pinch-zoom-pan";

function PlanBateau() {
    return (
        <div className="Carnet-Page">
            <h2>
                Le bateau
            </h2>
            <p>
                Voici un plan du bateau tel qu'il m'a été fourni.
            </p>
            <div style={{width: "100%", height: "50vh"}}>
                <PinchZoomPan>
                    <img src={plan_bateau} alt="plan bateau"/>
                </PinchZoomPan>
            </div>
            <div className="page-indicator">Page 2</div>
        </div>
    );
}

export default PlanBateau