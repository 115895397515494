import {createSelector, createSlice} from '@reduxjs/toolkit'

export const carnetSlice = createSlice({
    name: 'carnet',
    initialState: {
        solved: false,
        message: ""
    },
    reducers: {
        proposeSolution: (state, action) => {
            let solution = action.payload.trim().toLowerCase()
            console.log("proposed ", solution)
            state.message = ""
            state.solved = false
            const ok = (solution === "sextant")
            if (ok) {
                state.solved = true
            } else {
                state.message = "Pourquoi pas un " + solution + ", mais ce n'est pas ce que Daniel te conseille d'emporter"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution} = carnetSlice.actions

const selectSelf = (state) => state.chap1.carnet
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default carnetSlice.reducer