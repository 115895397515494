import {createSelector, createSlice} from '@reduxjs/toolkit'

export const mapuceSlice = createSlice({
    name: 'mapuce',
    initialState: {
        solved: false,
        message: "",
    },
    reducers: {
        proposeSolution: (state, action) => {
            let {
                exterior,
                smallplots,
                holesposition,
                highesthole,
                lowesthole
            } = action.payload
            state.message = ""
            let ok = true
            if(exterior !== "rectangle") {
                ok = false
            }
            if(smallplots !== "obelisque") {
                ok = false
            }
            if(holesposition !== "top") {
                ok = false
            }
            if(highesthole !== "rectangle") {
                ok = false
            }
            if(lowesthole !== "cercle") {
                ok = false
            }

            if (ok) {
                state.solved = true
            } else {
                state.message = "Ce n'est pas la bonne forme de monument"
            }
        },
    },
})

// Action creators are generated for each case reducer function
export const {proposeSolution} = mapuceSlice.actions

const selectSelf = (state) => state.chap3.mapuce
export const selectIsSolved = createSelector(selectSelf, state => !!state.solved)
export const selectMessage = createSelector(selectSelf, state => state.message)

export default mapuceSlice.reducer