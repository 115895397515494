import React, {useCallback, useEffect, useRef, useState} from 'react';
import "./epee.css"
import {useDispatch, useSelector} from "react-redux";
import {
    collectPoints,
    countDown,
    hideCountDown,
    nextWord,
    proposeSolution,
    REQUIRED_SCORE,
    resetGame,
    selectCountDownMax,
    selectCountDownShown,
    selectCountDownValue,
    selectCurrentColor,
    selectCurrentIndex,
    selectCurrentWord, selectIsSolved,
    selectIsSolving,
    selectIsStarted,
    selectPossibleColors,
    selectPossibleWords,
    selectProposed,
    selectScore,
    selectTimeToShow,
    selectToGuessColors,
    selectToGuessWords,
    selectValidities,
    selectWonPoints,
    selectWordsToShow,
    showCountDown,
    startGame,
    startSolving
} from "./feature";
import {addToInventory} from "../../../features/progress";
import {Link} from "react-router-dom";


function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function DifficultyLine({value, selected, onChange, children}) {

    return <label>
        <input type="radio" name="difficulty" onChange={onChange} checked={value === selected} value={value}/>
        {children}
    </label>
}

function StartGame({onStart}) {
    const [difficulty, setDifficulty] = useState("easy");
    const onChangeDifficulty = useCallback((e) => {
        console.log("On diff change", e.target.value)
        setDifficulty(e.target.value)
    }, [setDifficulty])
    const onStartButton = useCallback((e) => {
        onStart && onStart({difficulty})
    }, [difficulty, onStart])
    const lineProps = {
        selected: difficulty,
        onChange: onChangeDifficulty,
    }


    return (
        <React.Fragment>
            <p>
                Il est conseillé d'être deux pour résoudre ce défi. <br/>
                Des noms de couleurs vont être affichées successivement. Ces noms seront écrits d'une certaine
                couleur <br/>
                <i>(attention, il y a de fortes chances que le nom de la couleur ne soit pas lié à la couleur utilisée
                    pour l'écrire !)</i><br/>
            </p>
            <p>
                L'un des deux joueurs devra mémoriser le <b>mot</b> écrit, l'autre joueur devra se souvenir de la
                &#160;<b>couleur</b>&#160;avec laquelle le mot est écrit.<br/>
                Il faudra ensuite restituer ce qui a été mémorisé en inversant les roles :<br/>
                La personne qui aura retenu les mots devra pointer des couleurs, et celle qui a retenu les couleurs
                devra restituer des mots.
            </p>
            <div>
                <div>Choisi la difficulté :</div>
                <DifficultyLine value="easy" {...lineProps}> Facile </DifficultyLine>
                <DifficultyLine value="medium" {...lineProps}> Moyen </DifficultyLine>
                <DifficultyLine value="nightmare" {...lineProps}> Cauchemard </DifficultyLine>
                <br/>
                <div className="start-button-wrapper">
                    <button onClick={onStartButton}>C'EST PARTI !</button>
                </div>
            </div>
            <br/>
        </React.Fragment>
    )
}

function TextGuess() {
    const currentWord = useSelector(selectCurrentWord)
    const currentIndex = useSelector(selectCurrentIndex)
    const currentColor = useSelector(selectCurrentColor)

    return <div className="text-to-learn" style={{color: currentColor}}>
        <span className="number">#{currentIndex}&#160;</span>{currentWord}
    </div>;
}

function CountDownDisplay() {
    const countDownValue = useSelector(selectCountDownValue)
    return <div className="count-down">{countDownValue}</div>;
}

function GuessPhase() {
    const dispatch = useDispatch()
    const proposed = useSelector(selectProposed)
    const possibleWords = useSelector(selectPossibleWords)
    const possibleColors = useSelector(selectPossibleColors)
    const solutionWords = useSelector(selectToGuessWords)
    const solutionColors = useSelector(selectToGuessColors)
    const validities = useSelector(selectValidities)
    const wonPoints = useSelector(selectWonPoints)
    const formEl = useRef(null);
    const isSolved = useSelector(selectIsSolved)

    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        const formDOM = formEl.current
        const color = formDOM['proposed-color'].value
        const word = formDOM['proposed-word'].value
        dispatch(proposeSolution({color, word}))
        return false
    }, [dispatch, formEl])

    const clickCollect = useCallback((event) => {
        event.preventDefault()
        dispatch(collectPoints())
        if(isSolved){
            dispatch(addToInventory("epee"))
        }
    }, [dispatch, isSolved])

    return <div className="guess-phase">
        <ul className="already-solved">
            {proposed.map((it, idx) => {
                return <li key={`proposition-${idx}`}>
                    #{idx + 1} &#160;
                    <span style={{color: solutionColors[idx]}}>{solutionWords[idx]}</span>
                    {validities[idx] ?
                        <span style={{color: "green", fontWeight: "bold", fontSize: "25px"}}>&#160;&#8658;&#160;</span>
                        :
                        <span style={{color: "red", fontWeight: "bold", fontSize: "25px"}}>&#160;&#8655;&#160;</span>
                    }
                    <span style={{color: it.color}}>{it.word}</span>
                </li>
            })}
        </ul>
        {
            proposed.length < solutionWords.length ?
                (<form onSubmit={onFormSubmit} className="current-proposal" ref={formEl}>
                        <div className="current-proposal-number">#{proposed.length + 1}</div>
                        <div className="choices">
                            <div className="color-choices">
                                {
                                    possibleColors.map((it, idx) => (
                                        <label key={`proposed-color-${idx}`}>
                                            <input type="radio" name="proposed-color" value={it} required={true}/>
                                            <div className="proposed-color" style={{backgroundColor: it}}/>
                                        </label>
                                    ))
                                }
                            </div>
                            <div className="separator"/>
                            <div className="word-choices">
                                {
                                    possibleWords.map((it, idx) => (
                                        <label key={`proposed-color-${idx}`}>
                                            {it}
                                            <input type="radio" name="proposed-word" value={it} required={true}/>
                                        </label>
                                    ))
                                }
                            </div>
                        </div>
                        <button type="submit">VALIDER</button>
                    </form>
                )
                :
                <button onClick={clickCollect}>RÉCUPERER NOS {wonPoints} POINTS</button>
        }

    </div>
}
const COUNT_DOWN_TIME = 1

const runGuessDisplay = ({countDownMax, nbrToGuess, timeToGuess}) => async (dispatch, getState) => {
    dispatch(showCountDown())
    for (let t = countDownMax; t > 0; t--) {
        await timeout(COUNT_DOWN_TIME * 1000)
        if (!selectIsStarted(getState())) {
            return
        }
        dispatch(countDown())
    }
    dispatch(hideCountDown())
    for (let i = 0; i < nbrToGuess - 1; i++) {
        await timeout(timeToGuess * 1000)
        if (!selectIsStarted(getState())) {
            return
        }
        dispatch(nextWord())
    }
    await timeout(timeToGuess * 1000)
    if (!selectIsStarted(getState())) {
        return
    }
    dispatch(startSolving())
}


function Epee() {
    const dispatch = useDispatch()
    const onStart = useCallback((difficulty) => {
        dispatch(startGame(difficulty))
    }, [dispatch])

    const onReset = useCallback(() => {
        dispatch(resetGame())
    }, [dispatch])

    const started = useSelector(selectIsStarted)
    const countDownShown = useSelector(selectCountDownShown)
    const countDownMax = useSelector(selectCountDownMax)
    const nbrToGuess = useSelector(selectWordsToShow)
    const timeToGuess = useSelector(selectTimeToShow)
    const solving = useSelector(selectIsSolving)
    const score = useSelector(selectScore)
    const solved = useSelector(selectIsSolved)

    useEffect(() => {
        if (started) {
            dispatch(runGuessDisplay({countDownMax, nbrToGuess, timeToGuess}))
        }
    }, [dispatch, started, nbrToGuess, timeToGuess, countDownMax])
    return (
        <div className="written-page Epee-Page">
            <h2>
                Chap. I : Le jeu des couleurs
            </h2>
            <p>
                Tu auras besoin d'armement pour combattre si besoin. <br/>
                Tu obtiendras des armes en jouant au jeu des couleurs.
            </p>
            {score > 0 ? <p className="score-indicator">
                Score actuel : {score} / {REQUIRED_SCORE}
            </p> : null}
            {solved ? <p className="score-indicator">
                Tu devrais en avoir assez pour ton voyage. Tu peux continuer à augmenter ton score pour le plaisir. Sinon <br/>
                <Link to="/chap1/piece">Retourner à la piece des bagages</Link>
            </p>: null}
            {!started ? <StartGame onStart={onStart}/> : null}

            {started ? <React.Fragment>
                <div className="play-area">
                    <span className="joueur joueur1">&#8607;Je mémorise les MOTS&#8607;</span>
                    <span className="joueur joueur2">&#8607;Je mémorise les COULEURS&#8607;</span>
                    {solving ? <GuessPhase/> :
                        (countDownShown ? <CountDownDisplay/> : <TextGuess/>)}

                    <div className="reset-button-wrapper">
                        <button onClick={onReset}>Annuler</button>
                    </div>
                </div>
            </React.Fragment> : null}
            <br/>&#160;

        </div>
    );
}

export default Epee