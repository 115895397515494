import React, {useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './malaury.css'

import {
    askHelp,
    MAX_SCORE,
    proposeSolution,
    selectCurrentEnigme,
    selectIsSolved,
    selectMessage,
    selectSolvedLog
} from "./feature";
import {Link} from "react-router-dom";
import {addToInventory} from "../../../features/progress";

function Malaury() {
    const dispatch = useDispatch()
    const message = useSelector(selectMessage)
    const solved = useSelector(selectIsSolved)
    const logs = useSelector(selectSolvedLog)
    const enigme = useSelector(selectCurrentEnigme)
    const onFormSubmit = useCallback((event) => {
        event.preventDefault()
        const form = event.target
        dispatch(proposeSolution({
            proposal: form[`proposal-${enigme.idx}`].value,
        }))
    }, [dispatch, enigme])
    const requestHelp = useCallback((event) => {
        dispatch(askHelp())
        event.preventDefault()
    }, [dispatch])

    useEffect(() => {
        if (solved) {
            dispatch(addToInventory("notebook.p5"))
        }
    }, [dispatch, solved])
    return (
        <div className="written-page Page-Malaury">
            <h2>
                Chap. III : Malaury
            </h2>
            <p>
                Malaury Fourras t'accueille dans sa boutique. Sa boutique est perchée tout en haut d'un phare qui
                surplombe l'embouchure du fleuve.
            </p>
            <p>
                Tu lui racontes tes aventures et la raison pour laquelle tu viens sur les côtes du Brésil.<br/>
                En apprenant cela Malaury te dit qu'il a quelque chose qui pourrait t'aider dans ta quête. La page 5 du
                carnet d'Everett !
            </p>
            <p>
                Mais avant de te le donner, il doit s'assurer que tu as la trempe d'affronter le temple de Coyolxauhqui.<br/>
                Dans cette épreuve il y aura plusieurs mots mystères à deviner. <br/>
                Pour chaque mot mystère, une série de mots qui devraient te faire penser au mot mystère.<br/>
            </p>

            {message ? <p className="error">{message}</p> : null}
            {!message && logs.length > 0 ? <p>Bravo tu as trouvé le mot : {logs[logs.length - 1].solution}</p> : null}
            {solved ? <p>
                    Bravo ! Tu as tout trouvé. <br/>
                    Je te mets dans le carnet d'Everett la page numéro 5.<br/>
                    Tu peux me partager ton score final
                    : <b>{Math.round((MAX_SCORE - logs.reduce((p, it) => p + it.extraHelp, 0)) * 20 / MAX_SCORE)} / 20</b>
                    <br/>
                    <br/>
                    <Link
                        className="App-link"
                        to="/chap3/macapa">Retour à Macapa
                    </Link>
                </p> :
                <form onSubmit={onFormSubmit} method="GET">
                    <ul>
                        Indices :
                        {enigme.mots.map((it, idx) => (
                            <li key={`indice-${idx}`}>{it}</li>
                        ))}
                    </ul>
                    {enigme.hasMore ? <button onClick={requestHelp}>Demander un indice en plus</button> : null}
                    <label>
                        <input type="text" key={`proposal-${enigme.idx}`} name={`proposal-${enigme.idx}`}
                               placeholder="Ta réponse"/>
                    </label>
                    <button type="submit">Valider</button>
                </form>}
            {logs.length > 0 ? <div className="soluce">
                <ul>
                    Déjà trouvés :
                    {logs.map((it, idx) => (
                        <li key={`log-txt-${idx}`}>{it.solution} avec {it.extraHelp} aide</li>
                    ))}
                </ul>
            </div> : null}
        </div>
    );
}

export default Malaury