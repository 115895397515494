import React from 'react';

function CoffreTemple() {
    return (
        <div className="written-page Page-CoffreTemple">
            <h2>
                Chap. IV : Coffre du Temple
            </h2>
            <p>
                Bravo tu es presque à la fin de l'aventure !
            </p>
            <p>
                Un coffre t'as été donné en main propre. <br/>
                Tu devrais avoir toutes les informations nécessaire dans le carnet pour l'ouvrir.
            </p>
            <p>
                À toi de jouer...
            </p>
            <p>
                Une fois ouvert il sera temps pour toi de rentrer à Nassau.<br/>
                Les Pirahas te conseillent de passer par le Sud et remonter par le Brésil et les chutes d'Iguazu.<br/>
                Qui sait quel <b><i>PUZZLE</i></b> tu trouveras en passant par ce chemin, mais le paysage y est magnifique.<br/>
                Prend ton temps pour rentrer, l'important c'est le voyage ;) !
            </p>
        </div>
    );
}

export default CoffreTemple