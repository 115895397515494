import React, {useCallback} from 'react';
import crypto_aide1 from './page_crypted_help1.png'
import crypto_aide2 from './page_crypted_help2.png'
import {useDispatch, useSelector} from "react-redux";
import {addToInventory, selectHasInInventory} from "../../../features/progress";

function CryptoAide() {
    const hasHelp = useSelector(selectHasInInventory("notebook.p5"))
    const hasExtraHelp = useSelector(selectHasInInventory("notebook.p5.2"))
    const dispatch = useDispatch()
    const requestMore = useCallback(() => {
        dispatch(addToInventory("notebook.p5.2"))
    }, [dispatch])
    return (
        <div className="Carnet-Page">
            <h2>
                Crypto aide
            </h2>
            {hasHelp ? <div>
                <img src={crypto_aide1} alt="Crypto cercles"/>
            </div> : null}
            {hasExtraHelp ? <div>
                <img style={{width: "100%"}} src={crypto_aide2} alt="Crypto cercles"/>
            </div> : <React.Fragment>
                <p>
                    Il t'est possible d'obtenir plus d'aide en utilisant une bougie pour révéler le message écrit à
                    l'encre sympathique sur cette page.<br/>
                    Mais si tu le fais, les bougies coutant cher, ton score sera pénalisé.
                </p>
                <button onClick={requestMore}>Reveler de l'aide supplémentaire</button>
            </React.Fragment>}

            <div className="page-indicator">Page 5</div>
        </div>
    );
}

export default CryptoAide