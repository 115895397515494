import React, {useCallback} from 'react';
import {useDispatch, useSelector} from "react-redux";
import './pirahas.css'
import {
    computeItemKey,
    selectClickedItems,
    selectDeathCount,
    selectMessage,
    selectSuccess,
    toggleItem,
    validatePuzzle
} from "./feature";
import {Link} from "react-router-dom";

const DALLES_GRID = [
    ["C", "C", "C", "C", "C", "C", "C", "D", "C", "A", "B", "D", "B", "D", "B", "D", "C", "C", "C", "B"],
    ["C", "D", "A", "C", "A", "D", "C", "C", "E", "A", "D", "B", "B", "D", "B", "B", "C", "F", "C", "B"],
    ["C", "D", "A", "C", "A", "D", "C", "F", "F", "A", "B", "B", "B", "B", "B", "B", "B", "B", "B", "B"],
    ["B", "B", "B", "B", "B", "B", "B", "B", "B", "A", "C", "D", "B", "A", "B", "D", "C", "F", "C", "B"],
    ["C", "E", "D", "D", "D", "D", "C", "F", "F", "A", "C", "D", "B", "B", "B", "D", "C", "F", "C", "B"],
    ["C", "C", "C", "C", "C", "C", "C", "B", "B", "A", "C", "B", "C", "C", "C", "B", "C", "F", "C", "B"],
    ["E", "E", "D", "C", "A", "A", "A", "C", "A", "A", "B", "B", "B", "B", "B", "B", "C", "C", "C", "B"],
    ["E", "B", "D", "C", "B", "B", "B", "B", "B", "A", "C", "F", "D", "A", "C", "B", "C", "E", "C", "E"],
    ["E", "D", "D", "C", "A", "A", "A", "A", "A", "A", "C", "F", "D", "A", "C", "B", "C", "E", "C", "E"],
    ["E", "C", "C", "C", "C", "C", "F", "B", "B", "A", "C", "F", "D", "A", "C", "B", "C", "E", "C", "E"],
    ["E", "C", "F", "F", "F", "C", "F", "E", "E", "A", "C", "F", "D", "A", "C", "B", "C", "E", "C", "E"],
    ["E", "C", "D", "D", "D", "C", "F", "B", "B", "A", "B", "B", "B", "B", "B", "B", "C", "E", "C", "E"],
    ["E", "C", "C", "C", "C", "C", "C", "C", "C", "C", "E", "E", "E", "E", "E", "C", "C", "E", "C", "E"],
    ["E", "D", "B", "C", "F", "F", "F", "F", "F", "C", "D", "C", "B", "F", "B", "D", "C", "E", "C", "E"],
    ["E", "D", "B", "C", "C", "C", "C", "C", "C", "C", "D", "C", "B", "F", "A", "E", "C", "E", "C", "E"],
    ["E", "D", "D", "D", "D", "C", "D", "D", "D", "C", "D", "C", "B", "F", "F", "F", "F", "F", "C", "E"],
    ["E", "E", "E", "E", "E", "C", "C", "C", "C", "C", "D", "C", "B", "F", "B", "B", "B", "F", "C", "B"],
    ["D", "D", "D", "D", "D", "D", "D", "D", "D", "C", "D", "A", "A", "A", "A", "A", "A", "F", "A", "B"],
    ["C", "C", "C", "C", "C", "C", "C", "C", "C", "C", "D", "C", "B", "F", "B", "B", "B", "F", "A", "B"],
    ["B", "B", "B", "B", "B", "B", "B", "B", "B", "B", "D", "D", "D", "F", "F", "F", "F", "F", "A", "B"],
]
const COLORS = {
    "A": {
        color: "#ff0000",
        name: "red",
    },
    "B": {
        color: "#fffc29",
        name: "yellow",
    },
    "C": {
        color: "#0086ff",
        name: "blue",
    },
    "D": {
        color: "#00cc11",
        name: "green",
    },
    "E": {
        color: "#000000",
        name: "black",
    },
    "F": {
        color: "#8b725b",
        name: "brown",
    },
}

function Pirahas() {
    const dispatch = useDispatch()
    const selectedItems = useSelector(selectClickedItems)
    const deathCount = useSelector(selectDeathCount)
    const message = useSelector(selectMessage)
    const success = useSelector(selectSuccess)
    const onGridClick = useCallback((e) => {
        if(success){
            return
        }
        if(!e.target.classList.contains("itemGrid")){
            return
        }
        const clickedItem = e.target
        const data = clickedItem.dataset
        dispatch(toggleItem({rowIdx: data.rowIdx, colIdx: data.columnIdx}))
    }, [dispatch, success])
    const onValidate = useCallback(()=>{
        dispatch(validatePuzzle())
    }, [dispatch])
    return (
        <div className="written-page Page-Pirahas">
            <h2>
                Chap. IV : Pirahas
            </h2>
            <p>
                Les pirahas t'accueillent dans leur village.<br/>
                Le soir de ton arrivée et le lendemain, tu profites de leur hospitalité pour un repos bienvenu après
                plusieures semaines de navigation sur les fleuves. <br/>
                <br/>
                Bientôt il est temps de repartir et de te diriger avec ton équipage, à pieds, dans la jungle en
                direction du temple de Coyolxauhqui.<br/>
                Étant donné tes intentions, les Pirahas acceptent de t'aider à remettre la pièce en Or de Coyolxauhqui à
                sa place.<br/>
                Le jeune Olxgisai va t'accompagner dans la jungle.<br/>
                Mais c'est un Pirahas, et le concept de nombre et de couleur lui sont totalement étranger; à toi de bien
                interpreter ses indications.<br/>
                Tu sais juste qu'il y a le concept d'une unité, de quelques (entre 2 et 4 apparement), et de "des" au
                delà de 4.<br/>
                Au bout de quelques heures de marche, Olgxgisai te dis :
            </p>
            <blockquote>
                Tu es dans cette partie de la jungle. <br/>
                Des dangers existent dans cette partie de la jungle.<br/>
                Les dangers apportent la mort.<br/>
                Tu dois marcher quelques fois sur les dalles sang.<br/>
                Tu tournes sur le soleil du midi.<br/>
                Après quelques pas tourner sur le ciel du jour.<br/>
                Tu fais des pas sur le ciel du jour.<br/>
                Surtout ne pas aller juste avant le jaguar.<br/>
                Tourner pour un pas sur l'herbe.<br/>
                Puis aller sur le sang.<br/>
                Quelques pas sur le sang.<br/>
                Des pas sur le tronc.<br/>
                Sur le tronc sans passer à côter de l'herbe.<br/>
                Aller sur une seule dalle dans la rivière.<br/>
                Continuer sur le soleil jusqu'au temple.
            </blockquote>
            <p>
                Tu ne peux pas de déplacer en diagonale. Juste haut, bas, gauche et droite.
            </p>
            <div className={"dallesGrid"} onClick={onGridClick}>
                {DALLES_GRID.map((v, rowIdx) => (
                    v.map((c, colIdx) => (
                        <div key={`${rowIdx}-${colIdx}`} data-row-idx={rowIdx} data-column-idx={colIdx}
                             className={"itemGrid " + (selectedItems[computeItemKey(rowIdx, colIdx)] ? "selected": null)} style={{backgroundColor: COLORS[c].color}} />
                    ))
                ))}
            </div>
            <div style={{display: "flex", width: "100%", justifyContent: "end"}}><span style={{}}>&#160;T&#160;</span>
            </div>
            {success ? <>
                <div>Bravo tu as passé la pénultième épreuve.</div>
                <Link
                        className="App-link"
                        to="/chap4/coffretemple">
                    Accès au coffre !
                    </Link>
            </> : <>
                <div>Le template se trouve en bas à droite.</div>
                <div>Tu débutes où tu le souhaites sur la première ligne en haut !</div>
                <p>
                    Clique sur les cases pour tracer le chemin que tu emprunterai. Une fois le chemin tout tracé, appuie
                    sur
                    le bouton ci-dessous.<br/>
                    Tu peux annuler un pas en recliquant dessus.
                </p>
                {deathCount ? <div>Nombres de morts: {deathCount}</div> : null}
                {message ? <div className={"error"}>{message}</div> : null}
                <button onClick={onValidate}>Valider le chemin</button>
            </>}

        </div>
    );
}

export default Pirahas