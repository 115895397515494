import React from 'react';
import './meurtre.css'
import {Link} from "react-router-dom";

function Meurtre() {
    return (
        <div className="written-page Page-Meurtre">
            <h2>
                Chap. II : Meurtre en haute mer
            </h2>
            <p>
                Tu te réveilles, c'est le quatrième jour. Tu as tout ce dont tu as besoin dans ta cabine supérieure pour
                te faire un petit déjeuner rapide.<br/>
                Tu as bien dormi et réussi pour la première fois depuis
                le départ à passer une nuit de sommeil complète malgré ton sommeil léger. <br/>
                Il est 7h du matin tu sors de ta cabine. À peine as-tu franchi le seuil de ta porte que ton second t'intercepte :
            </p>
            <blockquote>Capt'ain Isaline ! Capt'ain Isaline ! J'ai une terrible nouvelle, il y a eu un meurtre cette
                nuit !
            </blockquote>
            <p>
                Edward est totalement affolé ! Tu lui demandes de se calmer et de t'exposer les faits.
            </p>
            <blockquote>
                Le maître artisan calfat a été assassiné à l'aube, le gabier Cheek Mulder et le matelot Bryan
                Kitchen l'ont retrouvé mort il y a une dizaine de minutes. <br/>
                Je suis allé constater le décès et il était encore chaud.<br/>
                Sa mort ne remonte pas à plus de deux heures !
            </blockquote>
            <p>
                Tu lui demandes quelle est la cause du décès.
            </p>
            <blockquote>
                Un couteau de cuisine planté en plein coeur ainsi qu'un pentagramme gravé au
                couteau sur son front.
            </blockquote>
            <p>
                Tu as déjà entendu parler d'un tel mode opératoire pour des meurtres. <br/>
                Ça s'était produit sur l'un des bateaux de Nassau. L'équipage avait été décimé et ça ne s'était arrêté
                que
                parceque le bateau avait fait demi-tour et était retourné au port.<br/>
                Il ne s'agit donc là que du premier meurtre d'une longue liste et si tu ne fais rien pour trouver et
                arrêter le
                coupable, il va falloir vite penser à revenir à Nassau toi aussi.<br/>
            </p>
            <p>
                Il est temps de mener l'enquête !
            </p>
            <p>
                Attention, ton équipage risque de te mentir, certains ont des choses à cacher, mais pas forcément le
                meurtre... Ces pirates ne souhaitent pas forcément que leur capitaine sache tout de leurs activités...<br/>
                Bref, pour cacher d'autres méfaits ils sont prêts à mentir et même à incriminer des personnes innocentes.
            </p>
            <Link
                className="App-link"
                to="/chap2/interrogatoire"
            >
                INTERROGER L'EQUIPAGE &#8250;
            </Link>
        </div>
    );
}

export default Meurtre